<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async doubleYBarLineChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 80, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 60, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain1, // [ymin, ymax]
        yDomain2,
        yRange = [height - marginBottom, marginTop], // [bottom, top]

        xPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        numberSuffix = "", //数字的后缀
        numberPosition = "inside", //数字显示在柱子里边还是外边
        labelPosition = "left", //label的位置在左边还是上边
        isFromZero = true, //是否从0开始
        isNegativeSameDirection = false, //默认负值柱子和正值柱子不同方向
        barRadius = 0, //柱子的圆角
        valueSuffix = "",
      } = {}
    ) {
      const curve = d3[curveName];
      this.duration = duration;
      console.log(data);

      const X = data.map((d) => d.label);
      const barMax = d3.max(data, (d) => d.bar);
      const barMin = d3.min(data, (d) => d.bar);
      const lineMax = d3.max(data, (d) => d.line);
      const lineMin = d3.min(data, (d) => d.line);

      //domain是数值范围
      //range是画图范围
      if (xDomain === undefined) xDomain = X;
      if (yDomain1 === undefined)
        yDomain1 = [barMin < 0 ? barMin * 1.1 : 0, barMax * 1.1];
      if (yDomain2 === undefined) yDomain2 = [lineMin * 0.9, lineMax * 1.1];

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale1 = d3.scaleLinear(yDomain1, yRange);
      const yScale2 = d3.scaleLinear(yDomain2, yRange);
      const yMagnify1 = d3.scaleLinear().domain(yRange).range(yDomain1); //计算y轴坐标和value对应关系 yMagnify(y)=>value
      const yMagnify2 = d3.scaleLinear().domain(yRange).range(yDomain2);

      this.xDomain = xDomain;
      this.xScale = xScale;
      this.ease = ease;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画xy轴
      const drawAxis = () => {
        const yTick = [1, 2, 3, 4, 5];
        const xTick = [1, 2, 3];
        const yTickSpace =
          (height - marginTop - marginBottom) / (yTick.length - 1);
        const xTickSpace =
          (width - marginLeft - marginRight) / (xTick.length - 1);
        const yGrid = svg
          .append("g")
          .attr("class", "x_grid")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(300)
          .attr("x2", (d, i) => width - marginRight);
        const y1Axis = svg
          .append("g")
          .attr("class", "y1_axis")
          .selectAll("text")
          .data(yTick)
          .enter()
          .append("text")
          .attr("class", "y_tick_text y1_tick_text")
          .text((d, i) => yMagnify1(marginTop + yTickSpace * i).toFixed(2))
          .attr("x", marginLeft - 5)
          .attr("y", (d, i) => marginTop + yTickSpace * i)
          .attr("dy", "0.5em")
          .attr("text-anchor", "end")
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);

        const y2Axis = svg
          .append("g")
          .attr("class", "y2_axis")
          .selectAll("text")
          .data(yTick)
          .enter()
          .append("text")
          .attr("class", "y_tick_text y2_tick_text")
          .text((d, i) => yMagnify2(marginTop + yTickSpace * i).toFixed(2))
          .attr("x", width - marginRight + 5)
          .attr("y", (d, i) => marginTop + yTickSpace * i)
          .attr("dy", "0.5em")
          .attr("text-anchor", "start")
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);
        // console.log(Math.floor(X.length / 2));
        const xTicks = [X[0], X[Math.floor(X.length / 2)], X[X.length - 1]];
        const xAxis = svg
          .append("g")
          .attr("class", "x_axis")
          .selectAll("text")
          .data(xTick)
          .enter()
          .append("text")
          .attr("class", "x_tick_text x_tick_text")
          .text((d, i) => xTicks[i])
          .attr("x", (d, i) => marginLeft + xTickSpace * i)
          .attr("y", (d, i) => height - marginBottom)
          .attr("fill", "currentColor")
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == 1) {
              return "middle";
            } else if (i == 2) {
              return "end";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawAxis();

      //画线
      const drawLine = async () => {
        const lineData = data.map((d) => d.line);
        const lineGroup = svg
          .append("g")
          .attr("class", "line_group")
          //   .attr("transform", `translateX(${})`);
          .attr("transform", "translate(" + xScale.bandwidth() / 2 + ",0)");
        const pathLine = d3
          .line()
          .curve(curve)
          .x((i) => xScale(X[i]))
          .y((i) => yScale2(lineData[i]));

        const svgLine = lineGroup
          .append("path")
          .attr("fill", "none")
          .attr("class", `line`)
          .attr("stroke", "black")
          .attr("d", pathLine(d3.range(lineData.length)));
        const svgLineTotalLength = svgLine.node().getTotalLength();
        svgLine
          .attr(
            "stroke-dasharray",
            svgLineTotalLength + "," + svgLineTotalLength
          )
          .attr("stroke-dashoffset", svgLineTotalLength)
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attr("stroke-dashoffset", 0);
        // }
      };
      await drawLine();

      //画面积
      const drawArea = () => {
        const area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.label);
          }) //对x轴进行缩放
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale2(d.line);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg
          .append("g")
          .attr("class", "area_group")
          .attr("transform", "translate(" + xScale.bandwidth() / 2 + ",0)");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient
          .append("stop")
          .attr("class", "area_top")
          .attr("offset", "0%");

        linearGradient
          .append("stop")
          .attr("class", "area_bottom")
          .attr("offset", "100%");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([data[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attrTween("d", () => {
            let index = d3.interpolate(0, data.length - 1);
            return (t) => {
              if (t <= 0) return area([data[0]]);
              let deg = Math.floor(index(t));
              return area(data.slice(0, deg + 1));
            };
          });
      };
      drawArea();

      //画柱子
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) => yScale1(0))
          .attr("width", (d, i) => xScale.bandwidth())
          .attr("height", 0)
          .attr("class", "bar")
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("y", (d, i) => (d.bar < 0 ? yScale1(0) : yScale1(d.bar)))
          .attr("height", (d, i) => Math.abs(yScale1(0) - yScale1(d.bar)));
      };
      drawBar();

      //画value
      const drawValue = async () => {};
      drawValue();

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
