<template>
    <div class="addition_item">
        <div>
            <h3 style="color: #FF3B30">{{ capitalDeal(data.fFund) }}</h3>
            <span>预案募集资金</span>
            <h3>{{ `${data.fFundRate.toFixed(2)}%` }}</h3>
            <span>预案募集占比</span>
        </div>
        <div class="line vline"></div>
        <div class="circle">
            <div>{{ data.sProcess }}</div>
            <h3>{{ data.sSecName }}</h3>
            <span>{{ data.sSecCode }}</span>
        </div>
        <div class="line vline"></div>
        <div>
            <h3>{{ `${data.fHairCuts.toFixed(2)}%` }}</h3>
            <span>折价率</span>
            <h3>{{ data.sProcessDate }}</h3>
            <span>公告更新日期</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data'],
    methods: {
        capitalDeal(fZljlr) {
            var capitalNumb;
            if(fZljlr===""){
                capitalNumb= "--万元";
            }
            fZljlr = Math.abs(fZljlr);
            if(fZljlr>0&&fZljlr<10000){
                capitalNumb= `${fZljlr.toFixed(2)}万元`;
            }else if(fZljlr>=10000){
                capitalNumb= `${(fZljlr/10000).toFixed(2)}亿元`;
            }
            return capitalNumb;
        },
    }
}
</script>
<style lang="less" scoped>
.addition_item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 34px;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:first-child {
            margin-right: 34px;
            h3 {
                font-size: 26px;
                font-weight: bold;
            }
            span {
                font-size: 20px;
                margin-bottom: 8px;
            }
        }
        &:last-child {
            margin-left: 34px;
            h3 {
                font-size: 26px;
                font-weight: bold;
            }
            span {
                font-size: 20px;
                margin-bottom: 8px;
            }
        }
    }
    .line {
        height: 1px;
        width: 124px;
        background-image:linear-gradient(to right, #000 0%, #000 50%, transparent 50%);
        background-size:28px 1px;
        background-repeat:repeat-x;
    }
    .circle {
        width: 134px;
        height: 134px;
        border-radius: 50%;
        background: #FF3B30;
        justify-content: center;
        color: #fff;
        > div {
            background: #fff;
            color: #FF3B30;
            padding: 0 4px;
            border-radius: 2px;
        }
        > h3 {
            font-size: 24px;
        }
    }
}
</style>

