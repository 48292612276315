<template>
  <div id="intelligentDiagnosis">
    <div class="pages hidden" id="intelligentDiagnosisPages">
      <div class="page_title"><span class="text hidden" id="intelligentDiagnosisText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <BasicMsg ref="refBasicMsg" v-if="basicMsgVisible" />
      <div ref="refFiveBusinessHolder" v-if="fiveBusinessVisible">
        <table-chart ref="refFiveBusinessTable" id="fiveBusinessTable" customClass="quote_table_chart" />
      </div>
      <div ref="refHistoryShapeHolder" v-if="historyShapeVisible">
        <chart-legend ref="refHistoryShapeLegend" id="historyShapeLegend" class="common_legend" />
        <line-chart ref="refHistoryShapeChart" id="historyShapeChart" customClass="line_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import tableChart from "@/components/tableChart/index.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import BasicMsg from './basicMsg.vue'
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      fiveBusinessVisible: false,
      historyShapeVisible: false,
      basicMsgVisible: false,
    };
  },
  components: {
    lineChart,
    tableChart,
    chartLegend,
    BasicMsg
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#intelligentDiagnosisText").addClass("hidden");
        this.fiveBusinessVisible = false
        this.historyShapeVisible = false
        this.basicMsgVisible = false
        this.updateDate = ''
        resolve();
      });
    },
    async show(commandData) {
      this.fiveBusinessVisible = false
      this.historyShapeVisible = false
      this.basicMsgVisible = false
      this.updateDate = ''
      //设置标题
      const title = commandData.data.pageTitle;
      $("#intelligentDiagnosisPages").removeClass("hidden");
      $("#intelligentDiagnosisText").html(title);

      this.$nextTick(async () => {
        $("#intelligentDiagnosisText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#intelligentDiagnosisText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (['买入前五营业部', '卖出前五营业部'].indexOf(commandData.type) !== -1) {
          this.handleFiveBusiness(commandData)
        } else if (commandData.type === '历史上榜后表现') {
          this.handleHistoryShape(commandData)
        } else {
          this.handleBasicMsg(commandData)
        }
      });
    },
    handleBasicMsg(commandData) {
      this.basicMsgVisible = true
      this.$nextTick(() => {
        this.renderBasicMsg(commandData)
      })
    },
    renderBasicMsg(commandData) {
      this.$refs.refBasicMsg.init(commandData.data.data)
      this.updateDate = commandData.date
    },
    handleFiveBusiness(commandData) {
      this.fiveBusinessVisible = true
      this.$nextTick(() => {
        this.renderFiveBusiness(commandData)
      })
    },
    renderFiveBusiness(commandData) {
      let data = [['', '买入(万)', '卖出(万)']]
      commandData.data.data.forEach(item => {
        data.push([
          item.sSaleDepName,
          item.fBuyAmountTxt,
          item.fSellAmountTxt
        ])
      });
      data.push([
        '总计',
        commandData.data.data.map(item => Number(item.fBuyAmountTxt)).reduce((a, b) => a+b).toFixed(2),
        commandData.data.data.map(item => Number(item.fSellAmountTxt)).reduce((a, b) => a+b).toFixed(2),
      ])
      let width = this.$refs.refFiveBusinessHolder.clientWidth;
      this.$refs.refFiveBusinessTable.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
      this.updateDate = commandData.date
    },
    handleHistoryShape(commandData) {
      this.historyShapeVisible = true
      this.$nextTick(() => {
        this.renderHistoryShape(commandData)
      })
    },
    renderHistoryShape(commandData) {
      let width = this.$refs.refHistoryShapeHolder.clientWidth;
      this.$refs.refHistoryShapeLegend.createLegend({
        legend: ['股价走势(元)',],
        legendType: ['dot'],
        colors: ["#000"],
      });
      this.$refs.refHistoryShapeChart.LineChart(commandData.data.data, {
        label: (d) => d.name,
        width: width,
        height: width * 0.75,
        marginLeft: 40,
        marginRight: 60,
        marginTop: 30,
        marginBottom: 50,
        duration: 900,
        delay: 50,
        ease: "easeCubic",
        isShowTxtTips: true,
        lineWidth: [4],
        yTickNumber: 6,
        xTickNumber: 2
      });
      this.updateDate = commandData.date
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
