<template>
    <div id="plateQuoteChart">
        <div class="pages hidden" id="plateQuotePages">
            <page-title id="plateQuoteChartText" ref="refPageTitle"></page-title>
            <div ref="refPlateQuotHolder" v-if="isPlateQuotVisible">
                <table-chart ref="refPlateQuotTable" id="plateQuotTable" :customClass="currentData"/>
            </div>        
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";


export default {
    data:function(){
        return{
            isPlateQuotVisible:false,//相关历史数据+排名
            currentData:"",

        }
    },
    components:{
        pageTitle,
        tableChart,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isPlateQuotVisible = false;
                resolve();
            });
        },
        async show(commandData){
                this.isPlateQuotVisible = false;

            $("#plateQuotePages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                this.handlePlateQuot(commandData);
            });
        },
/*==============================================*/
        handlePlateQuot(commandData){
             if(['涨幅榜',"跌幅榜","换手率榜","涨速榜","振幅榜","量比榜","成交额榜"].indexOf(commandData.type) > -1){
                this.currentData = "plate_quot_table_chart"
            }else if(["热门股票(按关注度)","热门股票(按多点信号强度)","上涨潜力排行(按形态预估)","上涨潜力排行"].indexOf(commandData.type) > -1){
                this.currentData = "spe_plate_quot_table_chart"
            } 
            this.isPlateQuotVisible = true;
            this.$nextTick(()=>{
                this.renderPlateQuot(commandData);
            });
        },
        renderPlateQuot(commandData) {
            let width = this.$refs.refPlateQuotHolder.clientWidth;
            this.$refs.refPlateQuotTable.TableChart(commandData.data.data,{
                width: width,
                height: width * 0.79,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
                col2Suffix: ["热门股票(按关注度)"].indexOf(commandData.type) > -1 ? "%" : "",
                col3Suffix: ["成交额榜","换手率榜"].indexOf(commandData.type) > -1 ? "" : ['涨幅榜',"跌幅榜","涨速榜","振幅榜"].indexOf(commandData.type) > -1 ? "%" : "",
                col4Suffix: ["上涨潜力排行"].indexOf(commandData.type) > -1 ? "%" : ""
            });
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>