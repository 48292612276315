var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    holder: true,
    debugging_window: true,
    is_landscape: _vm.isLandscape,
    is_show_pc: _vm.isShowPc,
  }},[_c('div',{class:{ logo: true, window_sit_top: _vm.customWindowClass == 'window_sit_top' }},[_c('logo-switcher',{ref:"refLogoSwitcher"})],1),_c('div',{staticClass:"broadcast_bg_media"},[_c('media-switcher',{ref:"refBroadcastBgMedia",attrs:{"isLoop":true}})],1),_c('div',{staticClass:"full_media"},[_c('media-cover',{ref:"refFullMedia"})],1),_c('div',{class:{
      window: true,
      window_sit_left: _vm.customWindowClass == 'window_sit_left',
      window_sit_top: _vm.customWindowClass == 'window_sit_top',
      theme_white: !_vm.isThemeBlack,
      window_visible: _vm.isWindowVisible,
    }},[_c('chart-switcher',{ref:"refChartSwitcher",attrs:{"subComponentName":_vm.subComponentName}}),_c('div',{staticClass:"window_reflection"})],1),_c('div',{class:`window_media ${_vm.customWindowClass} `},[_c('div',{staticClass:"window_media_inside"},[_c('media-switcher',{ref:"refWindowMedia",attrs:{"isFront":true}})],1)]),_c('div',{class:`window_media ${_vm.customWindowClass} `},[_c('div',{staticClass:"window_media_inside"},[_c('iframe-scroller',{ref:"refWindowIframe"})],1)]),_c('market-marquee',{ref:"refMarketMarquee"}),_c('multi-func-bar',{ref:"refMultiFuncBar",class:{ window_sit_top: _vm.customWindowClass == 'window_sit_top' }}),_c('snow-flakes',{ref:"refSnowFlakes"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }