<template>
  <div id="addition">
    <div class="pages hidden" id="additionPages">
      <div class="page_title"><span class="text hidden" id="additionText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div class="addition" ref="refAdditionHolder" v-if="isAddition">
        <Addition v-for="(item, index) in dataSource.slice(0, 4)" :key="index" :data="item" />
      </div>
      <div class="additionLifted" ref="refAdditionLiftedHolder" v-if="isAdditionLifted">
        <AdditionLifted v-for="(item, index) in dataSource.slice(0, 5)" :key="index" :data="item" />
      </div>
      <div class="additionList" ref="refAdditionListHolder" v-if="isAdditionList">
        <table-chart ref="refAdditionListChart" id="additionList" customClass="quote_table_chart" />
      </div>
      <div class="additionDetail" ref="refAdditionDetailHolder" v-if="isAdditionDetail">
        <AdditionDetail :data="dataSource" />
      </div>
      <div class="additionLiftedDetail" ref="refAdditionLiftedDetailHolder" v-if="isAdditionLiftedDetail">
        <AdditionLiftedDetail :data="dataSource" />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import tableChart from "@/components/tableChart/index.vue";
import AdditionLifted from './AdditionLifted.vue'
import Addition from './Addition.vue'
import AdditionDetail from './AdditionDetail.vue'
import AdditionLiftedDetail from './AdditionLiftedDetail.vue'
import starSvg from './star.svg'
import redStarSvg from './red_star.svg'
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isAddition: false,
      isAdditionLifted: false,
      isAdditionList: false,
      isAdditionDetail: false,
      isAdditionLiftedDetail: false,
      dataSource: [],
    };
  },
  components: {
    tableChart,
    AdditionLifted,
    Addition,
    AdditionDetail,
    AdditionLiftedDetail
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#additionText").addClass("hidden");
        this.isAddition = false
        this.isAdditionLifted = false
        this.isAdditionList = false
        this.isAdditionDetail = false
        this.isAdditionLiftedDetail = false
        this.dataSource = []
        resolve();
      });
    },
    async show(commandData) {
      this.isAddition = false
      this.isAdditionLifted = false
      this.isAdditionList = false
      this.isAdditionDetail = false
      this.isAdditionLiftedDetail = false
      this.dataSource = []
      //设置标题
      const title = commandData.data.pageTitle;
      $("#additionPages").removeClass("hidden");
      $("#additionText").html(title);

      this.$nextTick(async () => {
        $("#additionText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#additionText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.type === '定增优选') {
          this.handleAdditionList(commandData)
        } else if (commandData.type === "定增破发") {
          if (commandData.sec) {
            this.handleAdditionLiftedDetail(commandData)
          } else {
            this.handleAdditionLifted(commandData)
          }
        } else if (commandData.type === "定增计划") {
          if (commandData.sec) {
            this.handleAdditionDetail(commandData)
          } else {
            this.handleAddition(commandData)
          }
        }
      });
    },
    // 定增优选
    handleAdditionList(commandData) {
      this.isAdditionList = true
      this.$nextTick(() => {
        this.renderAdditionList(commandData)
      })
    },
    renderAdditionList(commandData) {
      let width = this.$refs.refAdditionListHolder.clientWidth;
      let data = []
      data.push(["名称代码",commandData.subType === 4 ? "破发比例" : "折价率","推荐指数","公告日期"])
      commandData.data.data.forEach((item) => {
        let str = ""
        for(let i = 0; i < item.fScore; i++) {
          str += `<img src=${redStarSvg} />`
        }
        if ((5 - item.fScore) > 0) {
          for(let i = 0; i < 5 - item.fScore; i++) {
            str += `<img src=${starSvg} />`
          }
        }
        data.push([
          item.sSecName,
          `${item.fFundRate.toFixed(2)}%` || `${item.fHairCuts.toFixed(2)}%`,
          str,
          item.sDate || item.sProcessDate
        ])
      })
      this.$refs.refAdditionListChart.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },
    // 定增破发
    handleAdditionLifted(commandData) {
      this.isAdditionLifted = true
      this.dataSource = commandData.data.data || []
      this.$nextTick(() => {
        this.renderAdditionLifted()
      })
    },
    renderAdditionLifted() {
      this.ffRow = anyElement({
          targets: document.querySelectorAll(".addition_lifted_item"),
          isSplitTxt: false,
          duration: 1100,
          delay: 200,
          easing: "easeOutExpo",
          inEffect: "topIn",
          outEffect: "leftOutBig",
      });
      this.ffRow.runIn();
    },
    handleAdditionLiftedDetail(commandData) {
      this.isAdditionLiftedDetail = true
      this.dataSource = commandData.data.data || {}
    },
    handleAddition(commandData) {
      this.isAddition = true
      this.dataSource = commandData.data.data || []
      this.$nextTick(() => {
        this.renderAddition()
      })
    },
    renderAddition() {
      this.ffRow = anyElement({
          targets: document.querySelectorAll(".addition_item"),
          isSplitTxt: false,
          duration: 1100,
          delay: 200,
          easing: "easeOutExpo",
          inEffect: "topIn",
          outEffect: "leftOutBig",
      });
      this.ffRow.runIn();
    },
    handleAdditionDetail(commandData) {
      this.isAdditionDetail = true
      this.dataSource = {
        ...commandData.data.data,
        subType: commandData.subType
      } || {}
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
