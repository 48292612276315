<template>
    <div id="goodwillWarnChart">
        <div class="pages hidden" id="goodwillWarnPages">
            <page-title id="goodwillWarnChartText" ref="refPageTitle"></page-title>
            <div class="page_date">{{ updateDate }}</div>
            <div ref="refGoodwillTableHolder" v-if="isGoodwillTableVisible">
                <table-chart ref="refGoodwillTable" id="goodwillTable" customClass="goodwill_table_chart"/>
            </div>
            <div ref="refDoubleYLinesHolder" v-if="isDoubleYLinesVisible">
                <chart-legend ref="refDoubleYLinesLegend" id="doubleYLinesLegend" class="lines_legend" />
                <double-y-lines-chart ref="refDoubleYLines" id="doubleYLines" customClass="double_y_lines_chart"/>
            </div>            
        </div>        
    </div>    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import doubleYLinesChart from "@/components/marketChart/doubleYLinesChart_goodwill.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";

export default {
    data:function(){
        return{
            updateDate:'',
            currentData:[],
            isGoodwillTableVisible:false,
            isDoubleYLinesVisible:false,
        }
    },
    components:{
        pageTitle,
        tableChart,
        doubleYLinesChart,
        chartLegend,
        lineChart,
        doubleYLinesChart,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isGoodwillTableVisible = false;
                this.isDoubleYLinesVisible = false;
                this.updateDate = "";
                resolve();
            });
        },
        async show(commandData){
            this.isGoodwillTableVisible = false; 
            this.isDoubleYLinesVisible =  false;

            $("#goodwillWarnPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["最新商誉信息"].indexOf(commandData.type) > -1){
                    this.handleGoodwillTable(commandData);
                }else if (["商誉总资产比例走势","商誉净资产比例走势"].indexOf(commandData.type) > -1){
                    this.handleDoubleYLines(commandData);
                }
            });
        },
/*==============================================*/
        handleGoodwillTable(commandData){
            this.isGoodwillTableVisible = true;      
            this.$nextTick(()=>{
                this.renderGoodwillTable(commandData);
            });
        },
        renderGoodwillTable(commandData) {
            let width = this.$refs.refGoodwillTableHolder.clientWidth;
            this.$refs.refGoodwillTable.TableChart(commandData.data.data,{
                width: width,
                height: width * 0.6,
                marginBottom:100,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
            this.updateDate = commandData.data.date
        },
/*==============================================*/
        handleDoubleYLines(commandData){
            this.isDoubleYLinesVisible = true;      
            this.$nextTick(()=>{
                this.renderDoubleYLines(commandData);
            });
        },
        renderDoubleYLines(commandData) {
            console.log(commandData.data.data.legend,'commandData');
                      
            //展示legend
            this.$refs.refDoubleYLinesLegend.createLegend({
                legend: commandData.data.data.legend,
                legendType: ["dot","dot","dot"],
                colors: ["#FFC100","#65BBFF","#FF5A37"],
            });
            let width = this.$refs.refDoubleYLinesHolder.clientWidth;
            this.$refs.refDoubleYLines.doubleYLinesChart(commandData.data.data.chartData,{
                width: width,
                height: width * 0.7,
                marginTop: 50,
                marginBottom: 50,
                marginLeft: 100,
                marginRight: 60,
                duration: 1000,
                delay: 40,
                ease: "easeCubicInOut",
                isShowTxtTips:false,
                xTickNumber : 3,
                unit:"%",
            });
            this.updateDate = commandData.data.date
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>