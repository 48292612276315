<template>
  <div id="marketChart">
    <div class="pages hidden" id="marketChartPages">
      <page-title id="marketChartText" ref="refPageTitle" />

      <div :class="{ stock_info: true, hidden: !isStockInfoVisible }">
        <div class="s_arise">
          <span :class="`s_price ${stockInfo.fNowClass}`">{{ stockInfo.fNow }}</span>
          <span :class="`s_percent ${stockInfo.fNowClass}`">{{ stockInfo.fNowPercent }}%</span>
        </div>
        <ul class="s_list">
          <li>
            <b>高</b><span :class="`${stockInfo.fMaxClass}`">{{ stockInfo.fMax }}</span>
          </li>
          <li>
            <b>开</b><span :class="`${stockInfo.fOpenClass}`">{{ stockInfo.fOpen }}</span>
          </li>
          <li>
            <b>低</b><span :class="`${stockInfo.fMinClass}`">{{ stockInfo.fMin }}</span>
          </li>
          <li v-if="stockInfo.fFhsl != '0.00'">
            <b>换手</b><span>{{ stockInfo.fFhsl }}%</span>
          </li>
          <li v-if="stockInfo.fFhsl == '0.00'">
            <b>昨收</b><span>{{ stockInfo.fClose }}</span>
          </li>
        </ul>
      </div>

      <div ref="refKChartHolder">
        <k-chart ref="refKChart" id="marketChartK" customClass="k_chart" />
      </div>

      <div ref="refTimeChartHolder">
        <time-chart ref="refTimeChart" id="marketChartTime" customClass="time_chart" />
      </div>

      <div ref="refSubChartHolder">
        <sub-chart ref="refSubChart1" id="marketChartSub1" customClass="sub_chart" />
        <sub-chart ref="refSubChart2" id="marketChartSub2" customClass="sub_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import kChart from "@/components/marketChart/kChart.vue";
import timeChart from "@/components/marketChart/timeChart.vue";
import subChart from "@/components/marketChart/subChart.vue";

export default {
  data: function () {
    return {
      isStockInfoVisible: true,
      stockInfo: {
        fMax: "",
        fMaxClass: "",
        fMin: "",
        fMinClass: "",
        fOpen: "",
        fOpenClass: "",
        fNow: "",
        fNowClass: "",
        fNowPercent: "",
        fFhsl: "",
        fClose: "",
      },
    };
  },
  components: {
    pageTitle,
    kChart,
    timeChart,
    subChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        this.isStockInfoVisible = false;
        await sleep(200);
        this.$refs.refKChart.playOut();
        this.$refs.refTimeChart.playOut();
        this.$refs.refSubChart1.playOut();
        this.$refs.refSubChart2.playOut();
        resolve();
      });
    },
    async show(commandData) {
      $("#marketChartPages").removeClass("hidden");
      this.$refs.refPageTitle.show(commandData);
      this.$nextTick(async () => {
        if (['分时', '五日'].indexOf(commandData.chartType) !== -1) {
          // 成交量、分时能量、资金博弈、量比、MACD
          this.renderTimeChart(commandData.data.finalData, commandData.chartType === '分时' ? 241 : 241*5);
          this.renderFigure(commandData, commandData.figure1 || '成交量', 'refSubChart1')
          this.renderFigure(commandData, commandData.figure2 || 'MACD', 'refSubChart2')
        } else {
          // 成交量、主力资金、MACD、KDJ、RSI、BOLL
          this.renderKLineChart(commandData.data.finalData, ['周K', '月K'].indexOf(commandData.chartType) !== -1 ? false : true)
          this.renderFigure(commandData, commandData.figure1 || '成交量', 'refSubChart1')
          this.renderFigure(commandData, commandData.figure2 || 'MACD', 'refSubChart2')
        }
        this.renderStockInfo(commandData.data.stockInfo);
      });
    },
    renderFigure(commandData, figure, refSubChart) {
      if (['分时', '五日'].indexOf(commandData.chartType) !== -1) {
        // 成交量、分时能量、资金博弈、量比、MACD
        switch (figure) {
          case '成交量':
            this.renderTimeVolumeChart(refSubChart, commandData.data.finalData, commandData.chartType === '分时' ? 241 : 241*5)
            break;
          case '分时能量':
            this.renderTimeVolume(refSubChart, commandData.data.finalData, commandData.chartType === '分时' ? 241 : 241*5)
            break;
          case '资金博弈':
            if (commandData.chartType === '分时') {
              this.renderFundsFight(refSubChart, commandData.data.finalData, 241)
            } else {// 五日
              this.renderCapitalAvg(refSubChart,  commandData.data.finalData)
            }
            break;
          case '量比':
            this.renderTimeVolumeRatio(refSubChart, commandData.data.finalData, commandData.chartType === '分时' ? 241 : 241*5)
            break;
          default:
            this.renderTimeMACD(refSubChart, commandData.data.finalData, commandData.chartType === '分时' ? 241 : 241*5)
        }
      } else {
        // 成交量、主力资金、MACD、KDJ、RSI、BOLL
        switch (figure) {
          case 'BOLL':
            this.renderBoll(refSubChart, commandData.data.finalData)
            break;
          case '主力资金':
            if (commandData.chartType === '日K') {
              this.renderKCapital(refSubChart, commandData.data.finalData)
            } else {
              this.renderCapitalAvg(refSubChart, commandData.data.finalData)
            }
            break;
          case 'MACD':
            this.renderKMACD(refSubChart, commandData.data.finalData, ['周K', '月K'].indexOf(commandData.chartType) !== -1 ? false : true);
            break;
          case 'KDJ':
            this.renderKdj(refSubChart, commandData.data.finalData)
            break;
          case 'RSI':
            this.renderRsi(refSubChart, commandData.data.finalData)
            break;
          case 'PB':
            this.renderKPB(refSubChart, commandData.data.finalData)
            break;
          case 'PE':
            this.renderKPE(refSubChart, commandData.data.finalData)
            break;
          case '神奇海波':
            this.renderKWave(refSubChart, commandData.data.finalData)
            break;
          default:
            this.renderKVolume(refSubChart, commandData.data.finalData, ['周K', '月K'].indexOf(commandData.chartType) !== -1 ? false : true);
        }
      }
    },
    //判断是否为股票
    isStock(seccode) {
      return seccode.substring(2, 4) == "01";
    },
    //右上角的详情信息
    renderStockInfo(stockInfo) {
      this.isStockInfoVisible = true;
      this.stockInfo = { ...stockInfo };
    },
    //主图K线图
    renderKLineChart(data, hiddenTime = false) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs.refKChart.KLineChart(data.data, {
        width: width,
        height: width * 0.4,
        marginTop: 2,
        marginBottom: 50,
        marginLeft: 2,
        marginRight: 2,
        duration: 50,
        delay: 10,
        ease: "easeCubicInOut",
        isDrawDK: true,
        xHidden: hiddenTime
      });
    },
    //主图分时图
    renderTimeChart(data, totalCount) {
      let width = this.$refs.refTimeChartHolder.clientWidth;
      this.$refs.refTimeChart.TimeChart(data, {
        width: width,
        height: width * 0.4,
        marginTop: 2,
        marginBottom: 50,
        marginLeft: 2,
        marginRight: 2,
        duration: 900,
        ease: "easeCubicInOut",
        totalCount: totalCount,
        xTicks: totalCount > 241 ? [data.startDate, data.endDate] : ["9:30", "11:30/13:00", "15:00"],
      });
    },
    //附图-分时和5日macd
    renderTimeMACD(refSubChart, data, totalCount) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].macdChart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 800,
        delay: 20,
        ease: "easeCubicInOut",
        totalCount: totalCount,
        xPadding: 0.4,
        isTicks: false,
        xTicks: totalCount == 241 ? ["09:30", "11:30/13:00", "15:00"] : [data.startDate, data.endDate],
      });
    },
     //附图-分时和5日 成交量
    renderTimeVolumeChart(refSubChart, data, totalCount) {
      let width = this.$refs.refTimeChartHolder.clientWidth;
      this.$refs[refSubChart].timeVolumeChart(data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 900,
        delay: 0,
        ease: "easeCubicInOut",
        totalCount: totalCount,
      });
    },
    // 附图-分时和5日 分时能量
    renderTimeVolume(refSubChart, data, totalCount) {
      let width = this.$refs.refTimeChartHolder.clientWidth;
      this.$refs[refSubChart].timeVolume(data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 900,
        delay: 0,
        ease: "easeCubicInOut",
        totalCount: totalCount,
      });
    },
    // 附图-分时 分时量比
    renderTimeVolumeRatio(refSubChart, data, totalCount) {
      let width = this.$refs.refTimeChartHolder.clientWidth;
      this.$refs[refSubChart].timeVolumeRatioChart(data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 900,
        delay: 0,
        ease: "easeCubicInOut",
        totalCount: totalCount,
      });
    },
    // 附图-分时 资金博弈
    renderFundsFight(refSubChart, data) {
      let width = this.$refs.refTimeChartHolder.clientWidth;
      this.$refs[refSubChart].fundsFight(data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        ease: "easeCubicInOut",
      });
    },

      //附图-K线成交量
    renderKVolume(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].kVolumeChart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 140,
        delay: 9,
        ease: "easeCubicInOut",
      });
    },
    // 附图-k线kdj
    renderKdj(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].k_kdj_chart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 140,
        delay: 9,
        ease: "easeCubicInOut",
      });
    },
    // 附图-k线rsi
    renderRsi(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].k_rsi_chart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 140,
        delay: 9,
        ease: "easeCubicInOut",
      });
    },
    // 附图-k线boll
    renderBoll(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].k_boll_chart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 140,
        delay: 9,
        ease: "easeCubicInOut",
      });
    },
    // 附图-k线主力资金
    renderCapitalAvg(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].capitalAvg(data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 20,
        marginLeft: 2,
        marginRight: 2,
        duration: 140,
        delay: 9,
        ease: "easeCubicInOut",
      });
    },
    // 附图-K线macd
    renderKMACD(refSubChart, data, hiddenTime = false) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].macdChart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 700,
        delay: 10,
        ease: "easeCubicInOut",
        totalCount: 60,
        xPadding: 0.4,
        isTicks: true,
        xHidden: hiddenTime
      });
    },
    //附图-日K线主力资金
    renderKCapital(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].kCapitalChart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 600,
        delay: 10,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },
    renderKPB(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].kPBchart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 600,
        delay: 10,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },
    renderKPE(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].kPEchart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 600,
        delay: 10,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },
    renderKWave(refSubChart, data) {
      let width = this.$refs.refKChartHolder.clientWidth;
      this.$refs[refSubChart].kwavechart(data.data, {
        width: width,
        height: width * 0.19,
        marginTop: 2,
        marginBottom: 40,
        marginLeft: 2,
        marginRight: 2,
        duration: 600,
        delay: 10,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },
    // monitorDrag() {
    //   let oldX, oldY, newX, newY
    //   document.onmousedown = function(e) {
    //       oldX = e.clientX;//记录初始光标相对于视窗坐标
    //       oldY = e.clientY;
    //       document.onmousemove = function(e){
    //           newX = e.clientX;//获取当前新光标相对于视窗坐标
    //           newY = e.clientY;
    //           console.log(oldX, oldY, newX, newY)
    //       }
    //       document.onmouseup = function(){
    //           document.onmousemove = null;
    //           document.onmouseup = null;
    //       }
    //   }
    // }
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
