<template>
  <div id="stocksFunds">
    <div class="pages hidden" id="stocksFundsPages">
      <div class="page_title"><span class="text hidden" id="stocksFundsText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>
      <div ref="refHorizenBarChartHolder" v-if="isHorizenBarChartVisible">
        <bar-chart-horizen ref="refHorizenBarChart" id="horizenBarChart" customClass="horizen_bar_chart" />
      </div>
      <div ref="refBarChartHolder" v-if="isBarChartVisible">
        <chart-legend ref="refBarLegend" id="barLegend" class="work_legend" />
        <bar-chart ref="refBarChart" id="barChart" customClass="bar_chart" />
      </div>
      <div ref="refdoubleYChartHolder" v-if="isDoubleYVisible">
        <chart-legend ref="refdoubleYLegend" id="doubleYLegend" class="work_legend" />
        <double-y-lines-chart ref="refdoubleYChart" id="doubleYChart" customClass="double_line" />
      </div>
      <div ref="refDountChartHolder" v-if="isDountVisible" class="flow">
        <donut-chart ref="refDonutChart" id="donutChart" customClass="donut_chart" />
        <ul class="flow_item">
          <li>主力流入<span class="flowIn">{{ dataSource.mainFlowIn }}</span></li>
          <li>主力流出<span class="flowOut">{{ dataSource.mainFlowOut }}</span></li>
        </ul>
        <ul class="flow_item">
          <li>净流入<span :class="[Number(dataSource.pureFlow) > 0 ? 'flowIn' : 'flowOut']">{{ dataSource.pureFlow }}</span></li>
        </ul>
        <ul class="flow_item">
          <li>超大单<span :class="[Number(dataSource.huge) > 0 ? 'flowIn' : 'flowOut']">{{ dataSource.huge }}</span></li>
          <li>大单<span :class="[Number(dataSource.big) > 0 ? 'flowIn' : 'flowOut']">{{ dataSource.big }}</span></li>
        </ul>
        <ul class="flow_item">
          <li>中单<span :class="[Number(dataSource.middle) > 0 ? 'flowIn' : 'flowOut']">{{ dataSource.middle }}</span></li>
          <li>小单<span :class="[Number(dataSource.small) > 0 ? 'flowIn' : 'flowOut']">{{ dataSource.small }}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import tableChart from "@/components/tableChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";
import barChart from "@/components/barChart/index_v1.0.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import doubleYLinesChart from "@/components/marketChart/doubleYLinesChart.vue";
import donutChart from "@/components/donutChart/index_v1.0.vue";
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: '',
      isQuoteTableVisible: false,
      isHorizenBarChartVisible: false,
      isBarChartVisible: false,
      isDoubleYVisible: false,
      isDountVisible: false,
      dataSource: {},
    };
  },
  components: {
    tableChart,
    barChartHorizen,
    barChart,
    chartLegend,
    doubleYLinesChart,
    donutChart
  },
  methods: {
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#stocksFundsText").addClass("hidden");
        this.isQuoteTableVisible = false
        this.isHorizenBarChartVisible = false
        this.isBarChartVisible = false
        this.isDoubleYVisible = false
        this.isDountVisible = false
        this.dataSource = {}
        resolve();
      });
    },
    async show(commandData) {
      this.isQuoteTableVisible = false
      this.isHorizenBarChartVisible = false
      this.isBarChartVisible = false
      this.isDoubleYVisible = false
      this.isDountVisible = false
      this.dataSource = {}
      //设置标题
      const title = commandData.data.pageTitle;
      $("#stocksFundsPages").removeClass("hidden");
      $("#stocksFundsText").html(title);

      this.$nextTick(async () => {
        $("#stocksFundsText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#stocksFundsText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (['沪深股通股东信息', '净流入'].indexOf(commandData.type) !== -1) {
          this.handleQuote(commandData)
        }
        if (['主力增减仓'].indexOf(commandData.type) !== -1) {
          this.handleHorizenBarChart(commandData)
        }
        if (['买卖股数'].indexOf(commandData.type) !== -1) {
          this.handleBarChart(commandData)
        }
        if (['累计持仓比例与收盘价'].indexOf(commandData.type) !== -1) {
          this.handleDoubleYChart(commandData)
        }
        if (['资金流向'].indexOf(commandData.type) !== -1) {
          this.handleDountChart(commandData)
        }
      });
    },
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    handleQuote(commandData) {
      this.isQuoteTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      let data = []
      if (commandData.type === '沪深股通股东信息') {
        data = [['股东名称', '持股量', '较昨日', '持股占总成本']]
        commandData.data.data.forEach((item) => {
          data.push([
            item.sHolderName,
            item.fHoldNumTxt,
            item.fNetHoldNumTxt,
            item.fHoldRatio
          ])
        })
      }
      if (commandData.type === '净流入') {
        data = [['交易日期', '净买卖股数', '估算净流入', '涨跌幅']]
        commandData.data.data.forEach((item) => {
          data.push([
            item.sDate,
            item.fNetHoldStockTxt,
            item.fNetBuyTxt,
            item.fChangePct
          ])
        })
      }
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col4Suffix: "%",
      });
    },
    handleHorizenBarChart(commandData) {
      this.isHorizenBarChartVisible = true;
      this.$nextTick(() => {
        this.renderHorizenBarChart(commandData);
      });
    },
    renderHorizenBarChart(commandData) {
      let width = this.$refs.refHorizenBarChartHolder.clientWidth;
      this.$refs.refHorizenBarChart.BarChart(commandData.data.data, {
        x: (d) => Number(d.value),
        y: (d) => d.label,
        numberSuffix: '万元',
        width: width,
        height: width * 0.76,
        marginLeft: "auto",
        marginRight: width * 0.25,
        duration: 900,
        delay: 120,
        yPadding: 0.4,
        ease: "easeQuad",
        numberPosition: "outside",
        labelPosition: "left",
        isNegativeSameDirection: true,
      });
    },
    handleBarChart(commandData) {
      this.isBarChartVisible = true;
      this.$nextTick(() => {
        this.renderBarChart(commandData);
      });
    },
    renderBarChart(commandData) {
      let width = this.$refs.refBarChartHolder.clientWidth;
      
      this.$refs.refBarLegend.createLegend({
          legend: ['沪股通净买卖股数（股）'],
          legendType: ['dot'],
          colors: ['#FF9C0F'],
      });
      this.$refs.refBarChart.BarChart(commandData.data.data, {
          x: (d) => d.name,
          y: (d) => Number(d.barValue),
          width: width,
          height: width * 0.72,
          marginLeft: 70,
          marginRight: 40,
          marginTop: 50,
          marginBottom: 50,
          duration: 1000,
          delay: 30,
          ease: "easeCircle",
          isShowNumber: false,
          xTickNumber: 3,
          yTickNumber: 5,
      });
    },
    handleDoubleYChart(commandData) {
      this.isDoubleYVisible = true
      this.$nextTick(() => {
        this.renderDoubleYChart(commandData);
      });
    },
    renderDoubleYChart(commandData) {
      this.$refs.refdoubleYLegend.createLegend({
        legend: ['沪深股通资金累计持仓比例（%）', '收盘价（元）'],
        legendType: ['dot','dot'],
        colors: ["#FF9C0F", "#246EFC"],
      });
      let width = this.$refs.refdoubleYChartHolder.clientWidth;
      this.$refs.refdoubleYChart.doubleYLinesChart(commandData.data.data, {
        width: width,
        height: width * 0.73,
        marginLeft: 50,
        marginRight: 70,
        marginTop: 50,
        marginBottom: 40,
        duration: 2000,
        delay: 40,
        ease: "easeQuadOut",
      });
    },
    handleDountChart(commandData) {
      this.isDountVisible = true
      this.$nextTick(() => {
        this.renderDountChart(commandData);
      });
    },
    renderDountChart(commandData) {
      this.dataSource = commandData.data.data
      let width = this.$refs.refDountChartHolder.clientWidth;
      this.$refs.refDonutChart.DonutChart(commandData.data.data.pieData, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: width,
        height: width * 0.5,
        donutRatio: 0.4, //donut的内圆半径占比
        padAngle: 2, //pad间隔角度
        cornerRadius: 5, //圆角
        colors: ['#46D47E', '#F82D39', '#FF5039', '#39B24E'],
        duration: 600,
        delay: 100,
        ease: "easeCubicInOut",
        labelPosition: "inside",
        valueSuffix: "%",
        isRotateLabel: false,
      });
      this.$nextTick(() => {
        this.ffRow = anyElement({
            targets: document.querySelectorAll(".flow_item"),
            isSplitTxt: false,
            duration: 1000,
            delay: 500,
            easing: "easeOutExpo",
            inEffect: "topIn",
            outEffect: "leftOutBig",
        });
        this.ffRow.runIn();
      })
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
