<template>
    <div class="basic_msg">
        <h2>{{`${dataSource.sSecName}(${dataSource.sDtSecCode})`}}</h2>
        <ul>
            <li>
                <span>涨跌幅</span>
                <span>{{ dataSource.fChangePctTxt }}%</span>
            </li>
            <li>
                <span>换手率</span>
                <span>{{ dataSource.fCHandPctTxt }}%</span>
            </li>
            <li>
                <span>成交量</span>
                <span v-html="dataSource.fDealSumTxt"></span>
            </li>
            <li>
                <span>成交金额</span>
                <span v-html="dataSource.fDealAmountTxt"></span>
            </li>
            <li style="width: 100%">
                <span>所属概念</span>
                <span>{{ dataSource.vBeIdeaTxt }}</span>
            </li>
        </ul>
        <div>
            <span>上榜原因</span>
            <span>{{ dataSource.vReason && dataSource.vReason[0] ? dataSource.vReason[0].sReasonMsg : ''}}</span>
        </div>
        <ul>
            <li>
                <span>买入总计</span>
                <span v-html="getFundNumTxt(dataSource.fBuyAmount * 10000)"></span>
            </li>
            <li>
                <span>卖出总计</span>
                <span v-html="getFundNumTxt(dataSource.fBuyAmount * 10000 - dataSource.fSellAmount * 10000)"></span>
            </li>
            <li>
                <span>净买入</span>
                <span v-html="getFundNumTxt(dataSource.fSellAmount * 10000)"></span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            dataSource: {}
        }
    },
    methods: {
        init(data) {
            this.dataSource = data
        },
        getFundNumTxt(num) {
            var txt;
            if (Math.abs(num) > 100000000) {
                txt = (num / 100000000).toFixed(2) + '<i>亿</i>';
            } else if (Math.abs(num) > 10000) {
                txt = (num / 10000).toFixed(2) + '<i>万</i>';
            } else {
                txt = (num).toFixed(2) + '<i>元</i>';
            }
            return txt;
        }
    },
}
</script>
