<template>
    <div class="addition_lifted_item">
        <span class="circle">
            <span>{{ data.sSecName }}</span>
            <span>{{ data.sSecCode }}</span>
        </span>
        <div>
            <span style="color: #FF3B30">{{ capitalDeal(data.fFund) }}</span>
            <span>解禁金额</span>
        </div>
        <div>
            <span>{{ `${data.fFundRate.toFixed(2)}%` }}</span>
            <span>解禁金额占比</span>
        </div>
        <div>
            <span>{{ `${data.fPriceRate.toFixed(2)}%` }}</span>
            <span>破发比例</span>
        </div>
        <span class="time">解禁日期：{{ data.sDate }}</span>
    </div>
</template>
<script>
export default {
    props: ['data'],
    methods: {
        capitalDeal(fZljlr) {
            var capitalNumb;
            if(fZljlr===""){
                capitalNumb= "--万元";
            }
            fZljlr = Math.abs(fZljlr);
            if(fZljlr>0&&fZljlr<10000){
                capitalNumb= `${fZljlr.toFixed(2)}万元`;
            }else if(fZljlr>=10000){
                capitalNumb= `${(fZljlr/10000).toFixed(2)}亿元`;
            }
            return capitalNumb;
        },
    }
}
</script>
<style lang="less" scoped>
.addition_lifted_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 120px;
    background-color: #735453;
    margin: 12px 36px 0 86px;
    padding: 0 24px 0 44px;
    color: #fff;
    border-radius: 6px;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
            &:first-child {
                font-size: 24px;
                font-weight: bold;
            }
        }
    }
    .circle {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #FF3B30;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
        > span {
            &:first-child {
                font-size: 24px;
            }
        }
    }
    .time {
        background: #FF3B30;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 30px;
        height: 30px;
        padding: 0 8px;
        &::before {
            position: absolute;
            content: " ";
            border: 15px solid transparent;
            border-color: transparent #FF3B30 #FF3B30 transparent;
            top: 0;
            left: 0;
            transform: translateX(-100%);
        }
    }
}
</style>