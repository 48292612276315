<template>
  <div class="media_holder">
    <div :class="{ active_image: true, hidden: isImageHidden }">
      <div
        class="background_image"
        :style="`background:url(${activeImage}) no-repeat center center / ${isFront ? 'contain' : 'cover'};`"
      ></div>
    </div>
    <div :class="{ active_video: true, hidden: isVideoHidden }">
      <video
        :src="activeVideo"
        autoplay
        muted
        :loop="isLoop"
        style="object-fit: cover; width: 100%; height: 100%; display: block"
      ></video>
    </div>
  </div>
</template>
<script>
import { sleep, imgPreloader } from "@/utils/utils.js";
export default {
  data: function () {
    return {
      activeImage: "", //当前活动图片
      activeVideo: "", //当前活动视频
      isImageHidden: true, //默认图片隐藏
      isVideoHidden: true, //默认视频隐藏
    };
  },
  props: ["isLoop", "isFront"],
  methods: {
    async show(src) {
      this.hide();
      await sleep(330);
      if (src.substr(-3) == "mp4") {
        this.videoIn(src);
      } else {
        this.imageIn(src);
      }
    },
    hide() {
      this.imageOut();
      this.videoOut();
    },
    async imageIn(src) {
      this.activeImage = src;
      this.isImageHidden = false;
    },
    async imageOut() {
      this.isImageHidden = true;
      await sleep(300);
      this.activeImage = "";
      await sleep(30);
    },
    async videoIn(src) {
      this.activeVideo = src;
      this.isVideoHidden = false;
    },
    async videoOut() {
      this.isVideoHidden = true;
      await sleep(300);
      this.activeVideo = "";
      await sleep(30);
    },
  },
  async mounted() {},
};
</script>
<style lang="less" scoped>
.media_holder {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  & > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.active_image {
  transition: all 0.6s;
  width: 100%;
  height: 100%;
  .background_image {
    width: 100%;
    height: 100%;
  }
}
.active_video {
  transition: all 0.6s;
  opacity: 0.96;
}
.hidden {
  opacity: 0 !important;
}
</style>
