<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async doubleYLinesChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain1, // [ymin, ymax]
        yDomain2,
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xPadding = 0.3,
      } = {}
    ) {
      this.duration = duration;

      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const line1Max = d3.max(data, (d) => d.line1);
      const line1Min = d3.min(data, (d) => d.line1);
      const line2Max = d3.max(data, (d) => d.line2);
      const line2Min = d3.min(data, (d) => d.line2);

      console.log(data);

      if (xDomain === undefined) xDomain = X;
      if (yDomain1 === undefined)
        (yDomain1 = [line1Min, line1Max]), (yDomain2 = [line2Min, line2Max]);
      //   console.log(yDomain1, yDomain2);

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale1 = d3.scaleLinear(yDomain1, yRange);
      const yScale2 = d3.scaleLinear(yDomain2, yRange);
      const yMagnify1 = d3.scaleLinear().domain(yRange).range(yDomain1); //计算y轴坐标和value对应关系 yMagnify(y)=>value
      const yMagnify2 = d3.scaleLinear().domain(yRange).range(yDomain2);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画网格
      const drawGrid = () => {
        const yTick = [1, 2, 3, 4, 5, 6, 7];
        const xTick = [1, 2];
        const yTickSpace =
          (height - marginTop - marginBottom) / (yTick.length - 1);
        const xTickSpace =
          (width - marginLeft - marginRight) / (xTick.length - 1);
        const rowLine = svg.append("g").attr("class", "y_tick_group");
        rowLine
          .append("g")
          .attr("class", "y_tick_line_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(300)
          .attr("x2", (d, i) => width - marginRight)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const y1Group = rowLine
          .append("g")
          .attr("class", "y1_tick_text_group")
          .selectAll("text")
          .data(yTick)
          .enter()
          .append("text")
          .attr("class", "y_tick_text y1_tick_text")
          .text((d, i) => yMagnify1(marginTop + yTickSpace * i).toFixed(2))
          .attr("x", marginLeft - 5)
          .attr("y", (d, i) => marginTop + yTickSpace * i)
          .attr("dy", "0.5em")
          .attr("text-anchor", "end")
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);

        const y2Group = rowLine
          .append("g")
          .attr("class", "y2_tick_text_group")
          .selectAll("text")
          .data(yTick)
          .enter()
          .append("text")
          .attr("class", "y_tick_text y2_tick_text")
          .text((d, i) => yMagnify2(marginTop + yTickSpace * i).toFixed(2))
          .attr("x", width - marginRight + 5)
          .attr("y", (d, i) => marginTop + yTickSpace * i)
          .attr("dy", "0.5em")
          .attr("text-anchor", "start")
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);

        const xAxis = svg.append("g").attr("class", "x_tick_group");
        xAxis
          .selectAll("text")
          .data(xTick)
          .enter()
          .append("text")
          .attr("class", "x_tick_text")
          .attr("fill", "currentColor")
          .text((d, i) => (i == 0 ? X[0] : X[X.length - 1]))
          .attr("x", (d, i) => (i == 0 ? marginLeft : width - marginRight))
          .attr("y", height - marginBottom)
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => (i == 0 ? "start" : "end"))
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);
      };
      drawGrid();

     

      //画面积
      const drawArea = () => {
        var area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.label);
          }) //对x轴进行缩放
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale2(d.line2);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg.append("g").attr("class", "area_group");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient
          .append("stop")
          .attr("class", "area_top")
        //   .attr("stop-color", "#178CEA")
        //   .attr("stop-opacity", "0.42")
          .attr("offset", "0%");

        linearGradient
          .append("stop")
          .attr("class", "area_bottom")
        //   .attr("stop-color", "#178CEA")
        //   .attr("stop-opacity", "0.16")
          .attr("offset", "100%");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([data[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attrTween("d", () => {
            let index = d3.interpolate(0, data.length - 1);
            return (t) => {
              let deg = Math.round(index(t));
              // console.log(deg);
              return area(data.slice(0, deg + 1));
            };
          });
      };
      drawArea();


       //画线
      const drawLine = () => {
        const lineData = [data.map((d) => d.line1), data.map((d) => d.line2)];
        const lineGroup = svg.append("g").attr("class", "line_group");
        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]))
            .y((i) => (j == 0 ? yScale1(Y[i]) : yScale2(Y[i])));

          const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", `line line${j + 1}`)
            .attr("stroke", "black")
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = svgLine.node().getTotalLength();
          this.svgLineTotalArr[j] = svgLineTotalLength;
          svgLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();
      

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
