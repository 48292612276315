<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import Chart from "./chart";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      yScale: null,
      yDomain: [],
      ease: "easeBack",
      // isHaveNegative: false,
      isPositive: false,
      noPositive: false,
    };
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.body`)
        .transition()
        .duration(400)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    RadarChart(
      data,
      {
        x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        // y = (d) => d, // given d in data, returns the (quantitative) y-value
        // z = () => 1,
        marginTop = 40, // the top margin, in pixels
        marginRight = 0, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        zDomain, // array of z-values
        zPadding = 0.05,
        xPadding = 0.1, // 柱子的比例
        yFormat = ",f", // a format specifier string for the y-axis
        delay = 100, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        numberSuffix = "", //数字后缀
        // xTickNumber = 5,
        // yTickNumber = 5,
        colors = d3.schemeTableau10,

        margins = { top: 80, left: 80, bottom: 50, right: 80 },
        textColor = "black",
        radius = 100,
        tickNum = 5,
        duration = 1000, //动画持续时长
        axisFillColor = ["white", "#ddd"],
        axisStrokeColor = "gray",
        pointsColor = "white",
        pointsSize = 3,
        lineColors = [],
        lineWidth = ["2", "2"],
      } = {}
    ) {
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("id", "svg")
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;
      const chart = new Chart();
      chart.margins(margins);
      chart.scaleRadius = d3.scaleLinear().domain([0, 100]).range([0, radius]);
      chart.renderAxes = function () {
        // ----渲染背景多边形-----
        const points = getPolygonPoints(data.length, radius, tickNum);
        const axes = chart
          .body()
          .append("g")
          .attr("class", "axes")
          .attr(
            "transform",
            "translate(" +
              chart.getBodyWidth() / 2 +
              "," +
              chart.getBodyHeight() / 2 +
              ")"
          )
          .selectAll("axis")
          .data(points);
          console.log(points)

        axes
          .enter()
          .append("polygon")
          .attr("class", "axis")
          .merge(axes)
          .attr("points", (d) => d)
          .attr("fill", "transparent")
          .attr("stroke", "transparent")
          .transition()
          // .delay((d, i) => i * delay)
          .duration(duration)
          .attr("fill", (d, i) =>
            i % 2 === 0 ? axisFillColor[0] : axisFillColor[1]
          )

          .attr("stroke", axisStrokeColor);
        axes.exit().remove();

        // ----渲染对角线-----
        const line = d3.line();
        const outerPoints = getOuterPoints(points[0]);
        const lines = d3.select(".axes").selectAll(".line").data(outerPoints);
        lines
          .enter()
          .append("path")
          .attr("class", "line")
          .merge(lines)
          .attr("d", (d) => {
            return line([
              [0, 0],
              [d[0], d[1]],
            ]);
          })
          .attr("stroke", "transparent")
          .transition()
          .duration(duration)
          .attr("stroke", axisStrokeColor);

        lines.exit().remove();

        //生成背景多边形的顶点
        function getPolygonPoints(vertexNum, outerRadius, tickNum) {
          const points = [];
          let polygon;

          if (vertexNum < 3) return points;
          const anglePiece = (Math.PI * 2) / vertexNum;
          const radiusReduce = outerRadius / tickNum;
          for (let r = outerRadius; r > 0; r -= radiusReduce) {
            polygon = [];
            for (let i = 0; i < vertexNum; i++) {
              polygon.push(
                Math.sin(i * anglePiece) * r +
                  "," +
                  Math.cos(i * anglePiece) * r
              );
            }
            points.push(polygon.join(" "));
          }
          return points;
        }
        //得到最外层多边形的顶点
        function getOuterPoints(outerPoints) {
          const points = outerPoints.split(" ").map((d) => d.split(","));
          return points;
        }
      };

      chart.renderText = function () {
        const texts = d3.select(".axes").selectAll(".label").data(data);
        texts
          .enter()
          .append("text")
          .attr("class", "label")
          .merge(texts)
          .attr(
            "x",
            (d, i) => Math.sin((i * Math.PI * 2) / data.length) * (radius + 20)
          )
          .attr(
            "y",
            (d, i) => Math.cos((i * Math.PI * 2) / data.length) * (radius + 20)
          )
          .attr("text-anchor", (d, i) => computeTextAnchor(data, i))
          .attr("dy", 6.5) //由于text-anchor属性在垂向上对齐文字底部，故需要使其对齐文字中部
          .text((d) => d.label)
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .attr("opacity", 1);

        function computeTextAnchor(data, i) {
          if (data.length < 3) return;
          const angle = (i * 360) / data.length;
          if (angle === 0 || Math.abs(angle - 180) < 0.01) {
            return "middle";
          } else if (angle > 180) {
            return "end";
          } else {
            return "start";
          }
        }
      };

      chart.renderPolygons = function () {
        const newData = handleData(data);
        const polygons = chart.body().selectAll(".polygons").data(newData);
        polygons
          .enter()
          .append("g")
          .attr("class", (d) => "g-" + d.value)
          .attr(
            "transform",
            "translate(" +
              chart.getBodyWidth() / 2 +
              "," +
              chart.getBodyHeight() / 2 +
              ")"
          )
          .append("polygon")
          .attr("class", "polygon")
          .merge(polygons)
          .attr("fill", "none")
          .attr("stroke", (d, i) => lineColors[i])
          .attr("stroke-width", (d, i) => lineWidth[i])
          .attr("points", (d, i) => {
            const miniPolygon = [];
            d.forEach(() => {
              miniPolygon.push("0,0");
            });
            return miniPolygon.join(" ");
          })
          .transition()
          .duration(duration)
          .attr("points", generatePolygons);

        polygons.exit().remove();
        //处理数据，转化数据结构，方便渲染
        function handleData(data) {
          const newData = [];
          Object.keys(data[0]).forEach((key) => {
            if (key !== "label") {
              const item = [];
              item.value = key;
              newData.push(item);
            }
          });

          data.forEach((d) => {
            newData.forEach((item, i) => {
              item.push([d.label, d["value" + (i + 1)]]);
            });
          });

          return newData;
        }
        //计算多边形的顶点并生成顶点圆圈
        function generatePolygons(d, index) {
          const points = [];
          const anglePiece = (Math.PI * 2) / d.length;
          d.forEach((item, i) => {
            const x = Math.sin(i * anglePiece) * chart.scaleRadius(item[1]);
            const y = Math.cos(i * anglePiece) * chart.scaleRadius(item[1]);
            //添加交点圆圈
            d3.select(".g-" + d.value)
              .append("circle")
              .attr("class", "point-" + d.value)
              .attr("fill", pointsColor)
              .attr("stroke", chart._colors(index))
              .attr("cx", 0)
              .attr("cy", 0)
              .attr("r", pointsSize)
              .transition()
              .duration(duration)
              .attr("cx", x)
              .attr("cy", y);
            points.push(x + "," + y);
          });
          return points.join(" ");
        }
      };
      chart.render = function () {
        chart.renderAxes();
        chart.renderText();
        chart.renderPolygons();
      };
      $("#" + this.id).html(svg.node());
      chart.renderChart(svg, width, height);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
