<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";

export default {
  props: ["id", "customClass"],
  data() {
    return {
      
    };
  },
  methods: {
    async playOut() {
      
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    ChipBarChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 80, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xRange = [marginLeft, width - marginRight], // [left, right]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 1000, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
      } = {}
    ) {
        let sourceData = data.data
        let nowPrice = data.nowPrice;
        let downPrice = data.downPrice;
        let downTxt = data.downTxt;
        let yMax = nowPrice + 30;
        let yMin = 0;
        // let yMin = d3.min(sourceData, (d) => d.name);
        let xMax = d3.max(sourceData, (d) => d.value);

        // 创建svg元素
        const svg = d3
          .create("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [0, 0, width, height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
        // y轴linear比例尺
        const yScale = d3.scaleLinear([yMin, yMax], yRange);
        const xScale = d3.scaleLinear([0, xMax], xRange)
        // 定义axis
        const yAxis = d3.axisLeft(yScale).ticks(8).tickSizeOuter(0);
        const xAxis = d3.axisBottom(xScale).ticks(10).tickSizeOuter(0);
        //y轴坐标轴
        const axisY = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("opacity", 0)
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick").attr("class", (d, i) => {
              return `tick tick_${i}`
            })
            g.selectAll(".tick line").attr("class", "tick_line").attr("stroke", "rgba(0,0,0,.1)").attr("opacity", 0);
          });
        axisY.selectAll(".tick line").transition().duration(duration).attr("class", "tick_line").attr("opacity", 1);
        axisY.transition().duration(duration).attr("opacity", 1);
        // Y轴单位
        svg.append("text").text('元').attr('x', marginLeft).attr('y', yScale(yMax)).attr("dx", "-18px").attr("fill", "currentColor")
        // x轴坐标
        const axisX = svg
          .append("g")
          .attr("class", "axis_x")
          .attr("opacity", 0)
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick line")
            .clone()
            .attr("y2", marginTop + marginBottom - height)
            .attr("stroke", "currentColor")
            .attr("class", "tick_long_line");
          });
        axisX.selectAll(".tick_long_line").transition().duration(400).attr("opacity", 0.1);
        axisX.transition().duration(duration).attr("opacity", 1);
        // x轴单位
        svg.append("text").text('万股').attr('x', xScale(xMax) + 20).attr('y', height - marginBottom).attr("dy", "18px").attr("fill", "currentColor")
        //柱子
        const bars = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(sourceData)
          .join("rect")
          .attr("x", (i) => xScale(0))
          .attr("y", (d, i) => {
            return yScale(d.name)
          })
          .attr("class", (d, i) => {
                return 'bar'
          })
          .attr("height", 2)
          .attr("width", 0)
        bars.transition()
          // .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("width", (d) => xScale(d.value) - marginLeft)
        //平均值线和数字
        if (nowPrice != undefined && nowPrice != 0) {
            var aveLine = svg.append("g").attr('class', 'g_ave')
            aveLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(nowPrice / 1) + 0.8)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(nowPrice / 1) + 0.8)
                .attr("class", "ave_line")
                .attr("stroke", "currentColor")
            aveLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(nowPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textAve")
                .attr("dy", "5px")
                .text('当前价')
                .attr("fill", "currentColor")
                // .text((nowPrice / 1).toFixed(2))
        }

        //支撑位线和数字
        if (downPrice != undefined && downPrice != 0) {
            var supportLine = svg.append("g").attr('class', 'g_downPrice')
            supportLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(downPrice / 1) + 0.5)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(downPrice / 1) + 0.5)
                .attr("class", "downPrice_line")
            supportLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(downPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textSupport")
                .attr("dy", "5px")
                .text(downTxt)
                // .text((downPrice / 1).toFixed(2))
        }
        // 插入svg元素
        $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
