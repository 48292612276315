<template>
  <div id="longHu">
    <div class="pages hidden" id="longHuPages">
      <div class="page_title"><span class="text hidden" id="longHuText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refLongHuChartHolder" v-if="isLongHuChartVisible">
        <table-bar-chart ref="refLongHuChart" id="longHuChart" customClass="table_bar_chart" />
      </div>
      <div ref="refLongHuStrategyHolder" v-if="isLongHuStrategyVisible">
        <strategy-chart ref="refLongHuStrategyChart" customClass="strategy_chart" />
      </div>
      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>
      <div ref="refLongHuLineHolder" v-if="isLongHuLineVisible" class="page_desc">
        <div>
          <h3 class="other_color">偏好板块</h3>
          <ul>
            <li v-for="(item, index) in iLHBDepInfo.plant" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <self-line-chart ref="refLongHuLine" id="longHuLine" customClass="link_chart" />
        <p class="other_color">3日跟卖成功率：<span>{{ iLHBDepInfo.fThreeDaySuccess }}%</span></p>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import tableBarChart from "@/components/marketChart/tableBarChart.vue";
import tableChart from "@/components/tableChart/index.vue";
import selfLineChart from "@/components/selfLineChart/hideXY.vue"
import strategyChart from './strategyChart.vue'
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      iLHBDepInfo: {},
      isLongHuChartVisible: false,  // 净买入排名
      isLongHuStrategyVisible: false, // 策略精选
      isQuoteTableVisible: false, // 营业部
      isLongHuLineVisible: false, // 跟卖成功率
    };
  },
  components: {
    tableBarChart,
    strategyChart,
    tableChart,
    selfLineChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#longHuText").addClass("hidden");
        this.isLongHuChartVisible = false;
        this.isLongHuStrategyVisible = false;
        this.isLongHuLineVisible = false
        this.isQuoteTableVisible = false
        this.updateDate = "";
        resolve();
      });
    },
    async show(commandData) {
      this.isLongHuChartVisible = false;
      this.isLongHuStrategyVisible = false;
      this.isQuoteTableVisible = false
      this.isLongHuLineVisible = false

      //设置标题
      const title = commandData.data.pageTitle;
      $("#longHuPages").removeClass("hidden");
      $("#longHuText").html(title);

      this.$nextTick(async () => {
        $("#longHuText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#longHuText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (['vSecList', 'vJgqcList', 'vYzbyList', 'vLHBReasonDepDetail'].indexOf(commandData.type) !== -1) {
          this.handleLongHuChart(commandData)
        } else if (['vZjqcList', 'vYzxsList', 'vCgslList'].indexOf(commandData.type) !== -1) {
          this.handleLongHuStrategy(commandData)
        } else if (['vSaleOrgList'].indexOf(commandData.type) !== -1) {
          this.handleQuote(commandData)
        } else {
          this.handleLongHuLine(commandData)
        }
      });
    },
    handleLongHuChart(commandData) {
      this.isLongHuChartVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderLongHuChart(commandData)
      });
    },
    renderLongHuChart(commandData) {
        this.updateDate = commandData.data.data.date;
        let data
        if (['vSecList', 'vJgqcList', 'vYzbyList'].indexOf(commandData.type) !== -1) {
          data = commandData.data.data.data.map((item) => {
              return {
                  label: `${item.sSecName}${Number(item.sReasonType) > 1 ? `(${item.sReasonType})` : ''}`,
                  // label: item.sSecName,
                  value1: 1 * (item.fIncome / 10000).toFixed(2),
                  value2: item.fChangePct.toFixed(2),
              }
          })
        } else {
          data = commandData.data.data.data.slice(0, 10).map((item) => {
            return {
                label: item.sSecName,
                value1: 1 * (item.fIncome / 10000).toFixed(2),
                // value1: item.fIncome.toFixed(2),
                value2: item.increase.toFixed(2),
            }
          })
        }
        let width = this.$refs.refLongHuChartHolder.clientWidth;
        this.$refs.refLongHuChart.tableBarChart(data, {
            width: width,
            height: width * 0.78,
            marginLeft: 180,
            marginRight: 240,
            marginTop: 80,
            marginBottom: 0,
            duration: 800,
            delay: 60,
            ease: "easeQuad",
            isNegativeSameDirection: true,
            yPadding: 0.3,
            numberPosition: "inside",
            // value1Suffix: ['vSecList', 'vJgqcList', 'vYzbyList'].indexOf(commandData.type) !== -1 ? '亿' : '万',
            value1Suffix: '亿',
            value2Suffix: '%',
            title: ['名称代码', '净买入额', '涨幅'],
        });
    },
    handleLongHuStrategy(commandData) {
      this.isLongHuStrategyVisible = true
      this.$nextTick(() => {
        this.renderLongHuStrategy(commandData)
      });
    },
    renderLongHuStrategy(commandData) {
      this.$refs.refLongHuStrategyChart.init(commandData)
    },
    handleQuote(commandData) {
      this.isQuoteTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      this.updateDate = commandData.data.data.date;
      let data = [['营业部名称', '参与股票', '净买入（万）', '3日跟买成功率']]
      commandData.data.data.data.forEach((item) => {
        data.push([
          item.sName,
          `${item.sStockNum}`,
          item.fIncome.toFixed(2),
          item.fThreeDaySuccess
        ])
      })
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col4Suffix: "%",
      });
    },
    handleLongHuLine(commandData) {
      this.isLongHuLineVisible = true
      this.$nextTick(() => {
        this.renderLongHuLine(commandData)
      });
    },
    renderLongHuLine(commandData) {
      this.updateDate = commandData.data.data.date;
      this.iLHBDepInfo = commandData.data.data.iLHBDepInfo || {};
      let width = this.$refs.refLongHuLineHolder.clientWidth;
      let data = commandData.data.data.data.map((item) => {
        return {
          name: item.xLine,
          value: item.yLine
        }
      })
      this.$refs.refLongHuLine.selfLineChart(data, {
        width: width,
        height: width * 0.54,
        marginLeft: 0,
        marginRight: 80,
        marginTop: 30,
        marginBottom: 36,
        duration: 1500,
        delay: 20,
      });
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
