<template>
  <div id="finance">
    <div class="pages hidden" id="financePages">
      <page-title id="financeTxt" ref="refPageTitle"></page-title>
      <div class="page_date">{{ updateDate }}</div>

      <div ref="refTableHolder" v-if="isFinanceTableVisible">
        <table-chart ref="refFinanceTable" id="financeTable" customClass="finance_table_chart" />
      </div>

      <div ref="refSummaryTableHolder" v-if="isFinanceSummaryTableVisible">
        <table-summary-chart
          ref="refFinanceSummaryTable"
          id="financeSummaryTable"
          customClass="finance_summary_table_chart"
        />
      </div>
      <div class="no_data" v-if="isNotData">
        暂无数据
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import tableSummaryChart from "@/components/tableSummaryChart/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      updateDate:'',
      isFinanceTableVisible: false, //财报普通表格
      isFinanceSummaryTableVisible: false, //财报表格概括型
      isNotData: false, 
    };
  },
  components: {
    pageTitle,
    tableChart,
    tableSummaryChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        this.isFinanceTableVisible = false;
        this.isFinanceSummaryTableVisible = false;
        this.updateDate = "";
        resolve();
      });
    },
    async show(commandData) {
      if (commandData) {
        this.isFinanceTableVisible = false;
        this.isFinanceSummaryTableVisible = false;
        this.isNotData = false
        $("#financePages").removeClass("hidden");
        //设置标题
        this.$refs.refPageTitle.show(commandData);
        this.$nextTick(async () => {
          await sleep(300);
          if (
            [
              "资产概况",
              "负债概况",
              "负债和股东权益概况",
              "经营活动中产生的现金概况",
              "投资活动中产生的现金概况",
              "筹资活动中产生的现金概况",
            ].indexOf(commandData.compareKey) > -1
          ) {
            this.handleFinanceSummaryTable(commandData);
          } else {
            this.handleFinanceTable(commandData);
          }
        });
      } else {
        await sleep(300);
        this.isNotData = true
      }
    },
    handleFinanceSummaryTable(commandData) {
      this.isFinanceSummaryTableVisible = true;
      this.$nextTick(() => {
        this.renderSummaryTableChart(commandData);
      });
    },
    renderSummaryTableChart(commandData) {
      let width = this.$refs.refSummaryTableHolder.clientWidth;
      const aniOptions=this.getAniOptions(commandData.data.data.length,1200,0.1)
      this.$refs.refFinanceSummaryTable.SummaryTableChart(commandData.data.data, {
        width: width,
        height: width * 0.76,
        duration: aniOptions.duration,
        delay: aniOptions.delay,
        ease: "easeOutCubic",
        titleRows: commandData.data.titleRows,
        summaryColors: commandData.data.summaryColors,
      });
    },
    handleFinanceTable(commandData) {
      this.isFinanceTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      let width = this.$refs.refTableHolder.clientWidth;
      const aniOptions=this.getAniOptions(commandData.data.data.length,1200,0.1)
      this.$refs.refFinanceTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.76,
        duration: aniOptions.duration,
        delay: aniOptions.delay,
        ease: "easeOutCubic",
        titleRows: commandData.data.titleRows,
      });
      this.updateDate = commandData.data.date;
    },
    getAniOptions(dataLength, totalSeconds, ratio) {
      const duration = totalSeconds / (ratio * (dataLength - 1) + 1);
      const delay = duration * ratio;
      return {
        duration: duration,
        delay: delay
      };
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
