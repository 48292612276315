<template>
  <div id="sharePledge">
    <div class="pages hidden" id="sharePledgePages">
      <div class="page_title"><span class="text hidden" id="sharePledgeText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>
      <div ref="refBarChartHolder" v-if="isBarChartVisible">
        <bar-chart ref="refBarChart" id="barChart" customClass="bar_chart" />
      </div>
      <div ref="refStockRatioHolder" v-if="isStockRatioVisible">
        <div class="cont_section">
            <div class="section_title">质押风险等级</div>
            <div class="rating_bar">
                <span>低风险</span>
                <span></span>
                <span></span>
                <span></span>
                <span>高风险</span>
                <div :class="['bar_point', currentLevel.className]">{{ currentLevel.levelName }}</div>
            </div>
        </div>
        <div class="section_title">股权质押比例（占总股本）</div>
        <line-chart ref="refStockRatio" id="StockRatio" customClass="line_chart" />
      </div>
      <div ref="refShareholderRiskHolder" v-if="isShareholderRiskVisible" class="shareholder_risk">
        <ul>
          <li>当前股价：<span>{{ data.nowPrice }}</span></li>
          <li>若股价：<span>{{ data.downTxt }}</span></li>
        </ul>
        <chip-bar-chart ref="refShareholderRisk" id="shareholderRisk" customClass="chip-dist-chart" />
        <div class="dynamicDetail">
          <p>{{ data.dynamicDetail.desc }}</p>
          <ul>
            <li v-for="(item, index) in data.dynamicDetail.belowDataCombined.slice(0, 4)" :key="index">
              <h3>{{ item.sFpshname }}</h3>
              <span>{{ item.lInvolvedsumTxt }}股</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="no_data" v-if="isNotData">
        该股票尚无重要股东质押记录
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import barChart from "@/components/barChart/index_v1.0.vue";
import tableChart from "@/components/tableChart/index.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import chipBarChart from './components/ChipBarChart.vue'
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: '',
      isQuoteTableVisible: false,
      isBarChartVisible: false,
      isStockRatioVisible: false,
      isShareholderRiskVisible: false,
      isNotData: false,
      currentLevel: {
        className: 'bar_point1',
        levelName: '低风险',
      },
      data: {},
    };
  },
  components: {
    barChart,
    tableChart,
    lineChart,
    chipBarChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#sharePledgeText").addClass("hidden");
        this.isQuoteTableVisible = false
        this.isBarChartVisible = false
        this.isStockRatioVisible = false
        this.isShareholderRiskVisible = false
        this.isNotData = false
        this.updateDate = ''
        this.data = ''
        resolve();
      });
    },
    async show(commandData) {
      this.isQuoteTableVisible = false
      this.isBarChartVisible = false
      this.isStockRatioVisible = false
      this.isShareholderRiskVisible = false
      this.isNotData = false
      this.updateDate = ''
       this.data = ''
      //设置标题
      const title = commandData.data.pageTitle;
      $("#sharePledgePages").removeClass("hidden");
      $("#sharePledgeText").html(title);

      this.$nextTick(async () => {
        $("#sharePledgeText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#sharePledgeText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.data.data === '该股票尚无重要股东质押记录') {
          return this.isNotData = true
        }
        if (['质押比例统计'].indexOf(commandData.type) !== -1) {
          this.handleBarChart(commandData)
        } else if (['质押比例排名', '行业数据', '重要股东质押'].indexOf(commandData.type) !== -1) {
          this.handleQuoteTable(commandData)
        } else if (['股权质押'].indexOf(commandData.type) !== -1) {
          this.handleStockRatio(commandData)
        } else if (['重要股东风险预测'].indexOf(commandData.type) !== -1) {
          this.handleShareholderRisk(commandData)
        }
      });
    },
    handleBarChart(commandData) {
      this.isBarChartVisible = true;
      this.updateDate = commandData.data.data.date
      this.$nextTick(() => {
        this.renderBarChart(commandData);
      });
    },
    renderBarChart(commandData) {
      let width = this.$refs.refBarChartHolder.clientWidth;
       this.$refs.refBarChart.BarChart(commandData.data.data.data, {
        x: (d) => d.name,
        y: (d) => d.value,
        width: width,
        height: width * 0.75,
        marginLeft: 50,
        marginRight: 25,
        marginTop: 40,
        marginBottom: 40,
        xPadding: 0.3,
        duration: 800,
        delay: 80,
        ease: "easeCubic",
        isFromZero: true,
        isShowNumber: true,
        numberSuffix: '家'
      });
    },
    handleQuoteTable(commandData) {
      this.isQuoteTableVisible = true;
      this.$nextTick(() => {
        this.renderQuoteTable(commandData);
      });
    },
    renderQuoteTable(commandData) {
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },
    changeCurrentLevel(value) {
      let levelName = ''
      switch (value) {
        case 'bar_point1':
          levelName = '低风险'
          break;
        case 'bar_point2':
          levelName = '中低风险'
          break;
        case 'bar_point3':
          levelName = '中风险'
          break;
        case 'bar_point4':
          levelName = '中高风险'
          break;
        default:
          levelName = '高风险'
      }
      return {
        className: value,
        levelName,
      }
    },
    handleStockRatio(commandData) {
      this.isStockRatioVisible = true;
      this.currentLevel = this.changeCurrentLevel(commandData.data.data.level)
      this.$nextTick(() => {
        this.renderStockRatio(commandData);
      });
    },
    renderStockRatio(commandData) {
      let width = this.$refs.refStockRatioHolder.clientWidth;
      this.$refs.refStockRatio.LineChart(commandData.data.data.data, {
        label: (d) => d.label,
        width: width,
        height: width * 0.54,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 20,
        marginBottom: 50,
        duration: 1000,
        delay: 80,
        ease: "easeCubic",
        dotCircleRadius: 0,
        isShowTxtTips: false,
        yDomain: [0, 100],
        yTickNumber: 10,
        xTickNumber: 2,
        unit: '%'
      });
    },
    handleShareholderRisk(commandData) {
      this.isShareholderRiskVisible = true
      this.data = commandData.data.data
      this.$nextTick(() => {
        this.renderShareholderRisk(commandData);
      });
    },
    renderShareholderRisk(commandData) {
      let width = this.$refs.refShareholderRiskHolder.clientWidth
      this.$refs.refShareholderRisk.ChipBarChart(commandData.data.data, {
        width: width,
        height: width * 0.5,
        marginTop: 20,
        marginLeft: 40,
        marginRight: 80,
      })
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
