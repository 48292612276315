<template>
    <div id="intelliChooseStockChart">
        <div class="pages hidden" id="intelliChooseStockPages">
            <page-title id="intelliChooseStockChartText" ref="refPageTitle"></page-title>
            <div ref="refIntelliChooseStockHolder" v-if="isIntelliChooseStockTableVisible">                
                <div class="page_date">{{ updateDate }}</div>
                <table-chart ref="refIntelliChooseStockTable" id="intelliChooseStockTable" customClass="intelli_choose_stock_table_chart"/>
            </div>
            <div ref="refIntelliChooseStockCommonHolder" v-if="isIntelliChooseStockCommonVisible" class="choose_stock_common">   
               <div class="choose_stock_desc">30天选股胜率</div>
               <div class="month_succ_percent">
                   <div class="succ_percent">{{fMonthSuccPercent}}</div>
                   <ul class="tag_name">
                       <li v-for="(item,index) in vtTagInfo" :key="index">{{item.sTagName}}</li>
                   </ul>               
               </div>
               <div class="desc">{{'简介:'+sDescription}}</div>
               <div class="increase">
                   <div>策略收益</div>
                   <div class="increase_detail">
                        <div>
                            <span>当日涨跌幅</span>
                            <span :class="currentData.fFirstIncreaseClass">{{fFirstIncrease}}</span>
                        </div>
                        <div>
                            <span>7日涨跌幅</span>
                            <span :class="currentData.fWeekIncreaseClass">{{fWeekIncrease}}</span>
                        </div>
                        <div>
                            <span>30日涨跌幅</span>
                            <span :class="currentData.fMonthIncreaseClass">{{fMonthIncrease}}</span>
                        </div>
                   </div>
               </div>
               <div class="intelli_sec">
                   <div>历史牛股</div>
                   <ul>
                       <li v-for="(item,index) in vIntelliSec" :key="index">
                           <span>{{item.sChnName}}</span>
                           <span>{{item.sOptime.slice(5)+'入选'}}</span>
                           <span :class="[item.fToNowIncrease>0?'num_rise':'num_fall']">{{(item.fToNowIncrease*100).toFixed(2)+'%'}}</span>
                           <span>累计涨幅</span>
                       </li>
                   </ul> 
               </div>
            </div>         
        </div>        
    </div>    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";

export default {
    data:function(){
        return{
            updateDate:'',
            currentData:"",
            isIntelliChooseStockTableVisible:false,
            isIntelliChooseStockCommonVisible:false,
            fMonthSuccPercent:'',
            vtTagInfo:[],
            sDescription:'',   
            fFirstIncrease:'',
            fWeekIncrease:'',  
            fMonthIncrease:'',
            vIntelliSec:[],
        }
    },
    components:{
        pageTitle,
        tableChart,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isIntelliChooseStockTableVisible = false;
                this.isIntelliChooseStockCommonVisible = false;
                this.updateDate = "";
                resolve();
            });
        },
        async show(commandData){
            this.isIntelliChooseStockTableVisible = false; 
            this.isIntelliChooseStockCommonVisible = false;
            $("#intelliChooseStockPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["入选个股"].indexOf(commandData.subType) > -1){
                    this.handleTable(commandData);
                }else if (["选股策略"].indexOf(commandData.subType) > -1){
                    this.handleCommon(commandData);
                }
            });
        },
/*==============================================*/
        handleTable(commandData){
            this.isIntelliChooseStockTableVisible = true;      
            this.$nextTick(()=>{
                this.renderTable(commandData);
            });
        },
        renderTable(commandData) {
            let width = this.$refs.refIntelliChooseStockHolder.clientWidth;
            this.$refs.refIntelliChooseStockTable.TableChart(commandData.data.data.stockDataArr,{
                width: width,
                height: width * 0.78,
                marginBottom:100,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
                col4Suffix: "%"
            });
            this.updateDate = commandData.data.data.sDate
        },
/*==============================================*/
        handleCommon(commandData){            
            this.currentData = commandData.data.data
            this.isIntelliChooseStockCommonVisible = true;      
            this.$nextTick(()=>{
                this.renderCommon(commandData);
            });
        },
        renderCommon(commandData) {
            this.fMonthSuccPercent = commandData.data.data.fMonthSuccPercent
            this.vtTagInfo = commandData.data.data.vtTagInfo
            this.sDescription = commandData.data.data.sDescription            
            this.fFirstIncrease = commandData.data.data.fFirstIncrease
            this.fWeekIncrease = commandData.data.data.fWeekIncrease 
            this.fMonthIncrease = commandData.data.data.fMonthIncrease
            this.vIntelliSec = commandData.data.data.vIntelliSec
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>