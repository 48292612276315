<template>
  <div
    class="background_image"
    :style="`background:url(${logoSrc}) right center / contain no-repeat;`"
  ></div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
export default {
  data: function () {
    return {
      logoSrc: "", //当前活动图片
    };
  },
  methods: {
    async show(src) {
      this.logoSrc = src;
    },
    hide() {
      this.logoSrc = "";
    },
  },
  async mounted() {},
};
</script>
<style lang="less" scoped>
.background_image {
  width: 100%;
  height: 100%;
  max-width: 450px;
}
</style>
