<template>
    <div class="addition_detail">
        <template v-if="data.subType === '详情'">
            <ul>
                <li>
                    <span>{{ capitalDeal(data.fFund) }}</span>
                    <span>预案募集资金</span>
                </li>
                <li>
                    <span>{{ `${data.fFundRate.toFixed(2)}%` }}</span>
                    <span>募集资金占比</span>
                </li>
                <li>
                    <span>{{ `${data.fHairCuts.toFixed(2)}%` }}</span>
                    <span>折价率</span>
                </li>
            </ul>
            <div class="detail_msg">
                <div>
                    <div v-for="(item, index) in data.vProgress" :key="index">
                        <span>{{ item.sProgress}}</span><span>{{ item.sDay}}</span>
                    </div>
                </div>
                <ul>
                    <li><span>收盘价</span><span>{{ data.fLatestPrice }}元</span></li>
                    <li><span>预案增发价</span><span>{{ data.fPrice }}元</span></li>
                    <li><span>预案发行数量</span><span>{{ data.fCount }}万股</span></li>
                    <li><span>公告日至今涨幅</span><span>{{ data.fIncrease.toFixed(2) }}%</span></li>
                    <li><span>所属行业</span><span>{{ data.sIndustry }}</span></li>
                </ul>
            </div>
        </template>
        <template v-else>
            <h2>{{ data.subType }}</h2>
            <p>{{ data.subType === '发行对象'? data.sObject : data.sProject}}</p>
        </template>
    </div>
</template>
<script>
export default {
    props: ['data'],
    methods: {
        capitalDeal(fZljlr) {
            var capitalNumb;
            if(fZljlr===""){
                capitalNumb= "--万元";
            }
            fZljlr = Math.abs(fZljlr);
            if(fZljlr>0&&fZljlr<10000){
                capitalNumb= `${fZljlr.toFixed(2)}万元`;
            }else if(fZljlr>=10000){
                capitalNumb= `${(fZljlr/10000).toFixed(2)}亿元`;
            }
            return capitalNumb;
        },
    }
}
</script>
<style lang="less" scoped>
.addition_detail {
    margin-top: 90px;
    > ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 60px;
        > li {
            width: 146px;
            height: 146px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            > span:first-child {
                font-size: 26px;
            }
            &:nth-child(1) {
                background: #FF3B30;
            }
            &:nth-child(2) {
                background: #FF7700;
            }
            &:nth-child(3) {
                background: #3C88D4;
            }
        }
    }
    .detail_msg {
        display: flex;
        justify-content: space-between;
        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: 24px;
            > div:first-child {
                color: #FF7700;
                 &::before {
                    background: #FF7700;
                }
            }
            > div:not(:first-child) {
                margin-top: 120px;
                &::after {
                    content: " ";
                    width: 1;
                    height: 120px;
                    border: 1px dashed #707070;
                    position: absolute;
                    bottom: 30px;
                    left: 7px;
                }
            }
            > div {
                display: flex;
                align-items: center;
                position: relative;
                &::before {
                    content: " ";
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: #707070;
                    margin-right: 8px;
                }
                > span:first-child {
                    margin-right: 12px;
                }
            }
        }
        > ul {
            flex: 1;
            font-size: 24px;
            > li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 45px;
            }
        }
    }
    > h2 {
        font-size: 36px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 40px;
        margin-top: -40px;
    }
    > p {
        font-size: 28px;
    }
}
</style>