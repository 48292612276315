<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async tableBarChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft + 12, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [marginTop, height - marginBottom], // [bottom, top]

        yPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        numberSuffix = "", //数字的后缀
        numberPosition = "inside", //数字显示在柱子里边还是外边
        labelPosition = "left", //label的位置在左边还是上边
        isFromZero = true, //是否从0开始
        isNegativeSameDirection = false, //默认负值柱子和正值柱子不同方向
        barRadius = 0, //柱子的圆角
        value1Suffix = "",
        value2Suffix = "",
        title = [],
      } = {}
    ) {
      this.duration = duration;

      const X = data.map((d) => d.value1);
      const X2 = data.map((d) => d.value2);
      const Y = data.map((d) => d.label);

      let suffixArr = [];
      if (typeof numberSuffix == "function") {
        suffixArr = d3.map(data, numberSuffix);
      }

      //预处理有负值的柱状图
      let isHaveNegative = false;
      X.map((item) => {
        if (item < 0) {
          isHaveNegative = true;
        }
      });
      //如果xMax最小值为负值，说明全为负值，则xMas取绝对值，
      //并且强制isNegativeSameDirection为ture
      let xMax = d3.max(X);
      if (xMax < 0) {
        xMax = Math.abs(d3.min(X));
        isNegativeSameDirection = true;
      }
      //isNegativeSameDirection为true会强制最小值从0开始
      if (isNegativeSameDirection) {
        isHaveNegative = false;
        isFromZero = true;
      }
      if (isHaveNegative) {
        isFromZero = false;
        labelPosition = "left";
        numberPosition = "outside";
      }
      this.isHaveNegative = isHaveNegative;

      //y轴最小值，假设全为正，则非0的最小值需要乘以一个缩小系数
      let xMin = isFromZero ? 0 : d3.min(X) * 0.9;
      //如果发现为负，则最小值需要乘以一个扩大系数
      if (isHaveNegative) {
        xMin = d3.min(X) * 1.1;
      }

      if (labelPosition == "top") {
        xRange = [5, width - marginRight];
      }

      //domain是数值范围
      //range是画图范围
      if (xDomain === undefined) xDomain = [xMin, xMax];
      if (yDomain === undefined) yDomain = Y;
      yDomain = new d3.InternSet(yDomain);
      const I = d3.range(X.length).filter((i) => yDomain.has(Y[i]));
      // console.log(yDomain);
      const xScale = d3.scaleLinear(xDomain, xRange);
      const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding);
      const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

      this.xDomain = xDomain;
      this.xScale = xScale;
      this.ease = ease;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画title
      const drawTitle = () => {
        const titleGroup = svg.append("g").attr("class", "title_group");
        const title1Text = titleGroup
          .append("text")
          .attr("class", "title_text")
          .attr("fill", "currentColor")
          .text(title[0])
          .attr("x", () => {
            return title.length == 1 ? 10 : marginLeft - 10;
          })
          .attr("y", marginTop - 25)
          .attr("text-anchor", () => {
            return title.length == 1 ? "start" : "end";
          })
          .attr("opacity", 0)
          .transition()
          .duration(1000)
          .attr("opacity", 1);

        const title2Text = titleGroup
          .append("text")
          .attr("class", "title_text")
          .attr("fill", "currentColor")
          .text(title[1])
          .attr("x", marginLeft + 12)
          .attr("y", marginTop - 25)
          .attr("text-anchor", "start")
          .attr("opacity", 0)
          .transition()
          .duration(1000)
          .attr("opacity", 1);

        const title3Text = titleGroup
          .append("text")
          .attr("class", "title_text")
          .attr("fill", "currentColor")
          .text(title[2])
          .attr("x", width - 10)
          .attr("y", marginTop - 25)
          .attr("text-anchor", "end")
          .attr("opacity", 0)
          .transition()
          .delay(200)
          .duration(1000)
          .attr("opacity", 1);

        const divisionLine = titleGroup
          .append("line")
          .attr("class", "division_line")
          .attr("x1", 10)
          .attr("y1", marginTop)
          .attr("x2", 10)
          .attr("y2", marginTop)
          .attr("stroke", "#484848")
          .attr("stroke-width", 2)
          .transition()
          .delay(100)
          .duration(600)
          .attr("x2", width - 10);
      };
      if (title.length > 0) {
        drawTitle();
      }

      //画label
      const drawLabel = () => {
        const axisY = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("text-anchor", "end")
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis)
          .call((g) => {
            g.select(".domain").remove();
            g.selectAll(".tick line").remove();
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          });
        axisY
          .selectAll(".text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3[ease + "Out"])
          .duration(duration)
          .attr("opacity", 1);
      };
      drawLabel();

      //画柱子
      const drawBar = () => {
        const bar = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(I)
          .join("rect")
          .attr("x", (i) => (isHaveNegative ? xScale(0) : xScale(xDomain[0])))
          .attr("y", (i) => yScale(Y[i]))
          .attr("rx", barRadius)
          .attr("ry", barRadius)
          .attr("class", (d, i) => (X[i] >= 0 ? "bar bar_positive" : "bar bar_negative"))
          .attr("width", 0)
          .attr("height", yScale.bandwidth());
        //柱子动画
        bar
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("x", (d, i) => {
            if (isHaveNegative && X[i] < 0) {
              return xScale(X[i]);
            } else {
              return xScale(0);
            }
          })
          .attr("width", (i) => {
            if (isHaveNegative) {
              return Math.abs(xScale(X[i]) - xScale(0)); //全部数据含负值时的正值情况
            } else {
              return xScale(Math.abs(X[i])) - xScale(xDomain[0]); //全部数据为正直时
            }
          });
      };
      drawBar();

      //画value1
      const drawValue1 = async () => {
        let valueIsIn = true;
        //用来计算value1文字宽度
        const demo = svg
          .append("g")
          .attr("class", "demo_group")
          .selectAll("text")
          .data(I)
          .join("text")
          .attr("opacity", "0")
          .attr("class", (d, i) => `demo_text demo_text${i}`)
          .attr("x", (d, i) => (isHaveNegative ? xScale(0) + 10 : xScale(xDomain[0]) + 10))
          .attr("y", (i) => yScale(Y[i]) + yScale.bandwidth() / 2)
          .attr("dy", "0.35em")
          .text((d, i) => data[i].value1 + value1Suffix)
          .attr("opacity", 0);
        await sleep(0);
        const widthArr = [];
        for (let i = 0; i < I.length; i++) {
          const textWidth = svg.selectAll(`.demo_text${i}`).node().getComputedTextLength();
          widthArr.push(textWidth);
        }
        const numbers = svg
          .append("g")
          .attr("class", "value1_group")
          .selectAll("text")
          .data(I)
          .join("text")
          .attr("opacity", "0")
          .attr("class", (d, i) => {
            const barWidth = isHaveNegative
              ? Math.abs(xScale(X[i]) - xScale(0))
              : xScale(Math.abs(X[i])) - xScale(xDomain[0]);
            return barWidth > widthArr[i] + 20
              ? "value1_text_inside value1_text"
              : "value1_text_outside value1_text";
          })
          .attr("x", (d, i) => {
            const initX = isHaveNegative ? xScale(0) : xScale(xDomain[0]);
            const barWidth = isHaveNegative
              ? Math.abs(xScale(X[i]) - xScale(0))
              : xScale(Math.abs(X[i])) - xScale(xDomain[0]);
            return barWidth > widthArr[i] + 20 ? initX + 10 : initX + barWidth + 10;

            // return isHaveNegative ? xScale(0) + 10 : xScale(xDomain[0]) + 10;
          })
          .attr("y", (i) => yScale(Y[i]) + yScale.bandwidth() / 2)
          .attr("dy", "0.35em")
          .text((d, i) => data[i].value1 + value1Suffix)
          .attr("text-anchor", "start")
          .attr("opacity", 0);

        numbers
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("opacity", "1");
      };
      drawValue1();

      //画value2
      const drawValue2 = () => {
        const numbers = svg
          .append("g")
          .attr("class", "value2_group")
          .selectAll("text")
          .data(I)
          .join("text")
          .attr("fill", "currentColor")
          .attr("opacity", "0")
          .attr("class", (d, i) =>
            X2[i] > 0 ? "value2 value2_positive" : "value2 value2_negative"
          )
          .attr("x", (i) => width - 10)
          .attr("y", (i) => yScale(Y[i]) + yScale.bandwidth() / 2)
          .attr("dy", "0.35em")
          .text((d, i) => X2[i] + value2Suffix)
          .attr("text-anchor", "end")
          .attr("opacity", 0);

        numbers
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("x", (i) => width - 10)
          .attr("opacity", "1");
      };
      drawValue2();

      /*
      !!important!!
     怎样才能保证文字居于bar的上方而不是间隙中间呢？
     答案是先往上偏移bandwidth的一半，让文字中线和bar的上边线对齐，接着用dy平移自身高度的0.7倍。完美！
      */
      if (labelPosition == "top") {
        const offsetY = yScale.bandwidth() / 2;
        axisY.attr("text-anchor", "start").attr("transform", `translate(15,-${offsetY})`);
        axisY.selectAll(".text").attr("dy", "-0.7em");
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
