<template>
    <div id="companyIntroChart">
        <div class="pages hidden" id="companyIntroPages">
            <page-title id="companyIntroChartText" ref="refPageTitle"></page-title>
            <div class="page_date">{{ updateDate }}</div>

            <div ref="refCompanyIntroductionHolder" v-if="isCompanyIntroductionVisible">
                <table-chart ref="refCompanyIntroductionTable" id="companyIntroductionTable" :customClass="currentData"/>
            </div>
            <div ref="refNetProfitCompareHolder" v-if="isNetProfitCompareVisible">
                <chart-legend ref="refNetProfitCompareLegend" id="netProfitCompareLegend" class="compare_legend" /> 
                <table-bar-chart ref="refNetProfitCompareTableBar" id="netProfitCompareTableBar" customClass="table_bar_chart"/>
            </div>
            <div ref="refRelationshipHolder" v-if="isRelationshipVisible">
                <div class="relationship_desc">
                    <div class="secname">{{currentData.secname}}</div>
                    <ul class="inv_info">
                        <li v-for="item in currentData.ship" :key="item.a">
                            <div>{{ item.a }}</div>
                            <div>{{ item.b }}</div>
                        </li>
                    </ul>
                    <div class="companyname">{{currentData.companyname}}</div>  
                    <div class="bg_table"></div>             
                </div>
                <table-chart ref="refRelationshipTable" id="relationshipTable" customClass="relationship_table_chart"/>
            </div>
            <div ref="refCapitalStructureHolder" v-if="isCapitalStructureVisible">
                <chart-legend ref="refCapitalStructureLegend" id="capitalStructureLegend" class="work_legend" />
                <double-y-lines-chart ref="refCapitalStructureLine" id="capitalStructureLine" customClass="capital_structure_line_chart"/>
                <table-chart ref="refCapitalStructureTable" id="capitalStructureTable" customClass="capital_structure_table_chart"/>
            </div>
            <div ref="refMainHolder" v-if="isMainHolderVisible">
                <chart-legend ref="refMainHolderLegend" id="mainHolderLegend" class="work_legend" />
                <double-y-bar-line-chart ref="refMainHolderBarLine" id="mainHolderBarLine" customClass="main_holder_table_chart"/>
                <table-chart ref="refMainHolderTable" id="mainHolderTable" customClass="capital_structure_table_chart"/>
            </div>
            <div ref="refIncomeProfitHolder" v-if="isIncomeProfitVisible">
                <chart-legend ref="refIncomeProfitLegend" id="incomeProfitLegend" class="work_legend" />                
                <div class="title_desc" id="titleDesc" v-if="isTitleDesc">{{currentData.desc}}</div>
                <bar-double-chart ref="refIncomeProfitBar" id="incomeProfitBar" customClass="income_profit_bar_chart"/>
            </div> 
            <div ref="refAssetsDebtHolder" v-if="isAssetsDebtVisible">
                <chart-legend ref="refAssetsDebtLegend" id="assetsDebtLegend" class="work_legend" />                
                <div class="title_desc" id="titleDesc" v-if="isTitleDesc">{{currentData.desc}}</div>
                <bar-double-chart ref="refAssetsDebtBar" id="assetsDebtBar" customClass="assets_debt_bar_chart"/>
            </div> 
            <div ref="refIndustryScaleHolder" v-if="isIndustryScaleVisible">
                <div class="title_desc" id="titleDesc" v-if="isTitleDesc">{{currentData.data.desc}}</div>
                <bar-chart ref="refIndustryScaleBar" id="industryScaleBar" :customClass="currentData.type == '行业规模'? 'industry_scale_bar_chart' : 'doctor_data_bar_chart'"/>
                <div class="chartDesc" v-if="isDesc">
                    <div class="count"><span>{{currentData.data.desc1}}</span><span class="count_color">{{currentData.data.iSatffCount+currentData.data.desc3}}</span></div>
                    <div class="ratio"><span>{{currentData.data.desc2}}</span><span class="count_color">{{currentData.data.iIndustryRank+'/'+currentData.data.iCompanyTotalCount}}</span></div>
                </div>
            </div>
            <div ref="refStaffCountAndIncomeHolder" v-if="isStaffCountAndIncomeVisible">
                <chart-legend ref="refStaffCountAndIncomeLegend" id="staffCountAndIncomeLegend" class="work_legend" />
                <double-y-lines-chart ref="refStaffCountAndIncomeDoubleYLines" id="staffCountAndIncomeDoubleYLines" customClass="staff_count_and_income_double_y_lines_chart"/>
            </div>
            <div ref="refStaffAnalyzeHolder" v-if="isStaffAnalyzeVisible">
                <radar-chart ref="refStaffAnalyzeRadar" id="staffAnalyzeRadar" customClass="staff_analyze_radar_chart"/>
                <div class="radarDesc">
                    <div><span class="score_color">{{currentData.descScore[0]}}</span><span class="text_color">分</span><br>工作效率</div>   
                    <div><span class="score_color">{{currentData.descScore[1]}}</span><span class="text_color">分</span><br>员工稳定性</div>   
                    <div><span class="score_color">{{currentData.descScore[2]}}</span><span class="text_color">分</span><br>人才引进</div>     
                </div>
                 <div class="totalDesc" v-if="isDesc">
                    <div class="total_desc">{{currentData.desc}}</div>
                    <div class="desc">综合评估：员工工作效率<span>{{currentData.desc1[0]}}</span>，员工稳定性<span>{{currentData.desc1[1]}}</span>，人才结构<span>{{currentData.desc1[2]}}</span>，公司人力资源格局<span>{{currentData.desc1[3]}}</span>，优于行业内<span>{{currentData.desc1[4]}}</span>%的公司</div>
                </div>
            </div>
            <div ref="refIncomeAveHolder" v-if="isIncomeAveVisible">                
                <chart-legend ref="refIncomeAveLegend" id="incomeAveLegend" class="work_legend" />
                <line-chart ref="refIncomeAveLine" id="incomeAveLine" customClass="income_ave_line_chart"/>
                 <div class="chartDesc" v-if="isDesc">
                    <div class="count"><span>{{currentData.desc1}}</span><span class="count_color">{{currentData.value1+currentData.desc3}}</span></div>
                    <div class="ratio"><span>{{currentData.desc2}}</span><span class="count_color">{{currentData.iIndustryRank+'/'+currentData.iCompanyTotalCount}}</span></div>
                </div>
            </div>
            <div ref="refStaffEducationHolder" v-if="isStaffEducationVisible">
                <chart-legend ref="refStaffEducationLegend" id="staffEducationLegend" class="staff_legend" />
                <bar-stacked ref="refStaffEducationBar" id="staffEducationBar" customClass="staff_education_bar_chart"/>
            </div> 
            
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import barChart from "@/components/barChart/index_v1.0.vue";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import barStacked from "@/components/barStacked/index_vertical.vue";
import doubleYLinesChart from "@/components/marketChart/doubleYLinesChart_companyIntro.vue";
import doubleYLinesChart2 from "@/components/marketChart/doubleYLinesChart.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import doubleYBarLineChart from "@/components/marketChart/doubleYBarLineChart_companyIntro.vue";
import doubleYBarLineChart1 from "@/components/marketChart/doubleYBarLineChart.vue";
import radarChart from "@/components/radarChart/index.vue";
import tableBarChart from "@/components/marketChart/tableBarChart.vue";


export default {
    data:function(){
        return{
            updateDate:'',
            currentData:[],
            isCompanyIntroductionVisible:false,//公司介绍
            isNetProfitCompareVisible:false,//净利润比较
            isRelationshipVisible:false,//关系
            isCapitalStructureVisible:false,//股本结构
            isMainHolderVisible:false,//主力持仓
            isIncomeProfitVisible:false,//收入和利润情况
            isAssetsDebtVisible:false,//资产负债情况
            isIndustryScaleVisible:false,//行业规模
            isStaffCountAndIncomeVisible:false,//员工人数和营业收入
            isStaffAnalyzeVisible:false,//员工分析
            isIncomeAveVisible:false,//人均营业收入
            isTitleDesc:false, 
            isDesc:false,//图下的描述
            isStaffEducationVisible:false,//员工学历构成

        }
    },
    components:{
        pageTitle,
        tableChart,
        barChart,
        doubleYLinesChart,
        chartLegend,
        lineChart,
        doubleYBarLineChart,
        doubleYBarLineChart1,
        radarChart,
        tableBarChart,
        doubleYLinesChart2,
        barDoubleChart,
        barStacked,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                
                $("#companyIntroChart #titleDesc").addClass("hidden");
                this.isCompanyIntroductionVisible = false;
                this.isNetProfitCompareVisible = false;
                this.isRelationshipVisible=false;
                this.isCapitalStructureVisible=false;
                this.isMainHolderVisible=false;
                this.isIncomeProfitVisible=false;
                this.isAssetsDebtVisible=false,
                this.isIndustryScaleVisible=false;
                this.isStaffCountAndIncomeVisible=false;
                this.isStaffAnalyzeVisible=false;
                this.isIncomeAveVisible=false;  
                this.isTitleDesc=false;          
                this.isDesc=false;
                this.isStaffEducationVisible = false;
                this.updateDate ="";
                resolve();
            });
        },
        async show(commandData){
            this.isCompanyIntroductionVisible = false;            
            this.isNetProfitCompareVisible = false;
            this.isRelationshipVisible=false;
            this.isCapitalStructureVisible=false;
            this.isMainHolderVisible=false;
            this.isIncomeProfitVisible=false;
            this.isAssetsDebtVisible=false,
            this.isIndustryScaleVisible=false;
            this.isStaffCountAndIncomeVisible=false;
            this.isStaffAnalyzeVisible=false;
            this.isIncomeAveVisible=false;
            this.isTitleDesc=false; 
            this.isDesc=false;
            this.isStaffEducationVisible = false;

            $("#companyIntroPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["公司介绍","参股控股公司","董事和高管","高管","主营业务","分红配送","十大流通股东","十大股东","经营状况概述"].indexOf(commandData.type) > -1){
                    this.handleCompanyIntroduction(commandData);
                }else if (["净利润行业对比","营业收入行业对比","每股收益行业对比","市盈率行业对比"].indexOf(commandData.type) > -1){
                    this.handleNetProfitCompare(commandData);
                }else if (commandData.type == "关系"){
                    this.handleRelationship(commandData);
                }else if (commandData.type == "股本结构"){
                    this.handleCapitalStructure(commandData);
                }else if (commandData.type == "主力持仓"){
                    this.handleMainHolder(commandData);
                }else if (["收入和利润情况","现金净流量"].indexOf(commandData.type) > -1){
                    this.handleIncomeProfit(commandData);
                }else if (commandData.type == "资产负债情况"){
                    this.handleAssetsDebt(commandData);
                }else if (commandData.type == "员工人数和营业收入"){
                    this.handleStaffCountAndIncome(commandData);
                }else if (commandData.type == "员工分析"){
                    this.handleStaffAnalyze(commandData);
                }else if (["博士员工学历占比","硕士员工学历占比","本科员工学历占比","大专员工学历占比","人均营业收入", "普通员工薪酬概况","高管薪酬概况","员工增长情况","人均营业利润","本科员工比例","大专以下员工学历占比","销售岗员工占比","生产岗员工占比","技术岗员工占比","财务岗员工占比","行政岗员工占比","其他岗员工占比"].indexOf(commandData.type) > -1){
                    this.handleIncomeAve(commandData);
                }else if (["博士员工人数", "硕士员工人数","行业规模"].indexOf(commandData.type) > -1){
                    this.handleIndustryScale(commandData);
                }else if (["员工学历构成", "员工职业构成"].indexOf(commandData.type) > -1){
                    this.handleStaffEducation(commandData);
                }
            });
        },
/*=====================个股百科-公司介绍=========================*/
        handleCompanyIntroduction(commandData){
            this.isCompanyIntroductionVisible = true;
            if(['公司介绍','高管'].indexOf(commandData.type) > -1){
                this.currentData = "company_introduction_table_chart"
            }else if(['参股控股公司','主营业务'].indexOf(commandData.type) > -1){
                this.currentData = "company_share_holder_table_chart"
            }else if(['经营状况概述'].indexOf(commandData.type) > -1){
                this.currentData = "operate_desc_table_chart"
            }else if(['董事和高管','分红配送'].indexOf(commandData.type) > -1){
                this.currentData = "execut_table_chart"
            }else{
                this.currentData = "holder_table_chart"
            }                     
            // this.currentData = commandData.type;
            this.$nextTick(()=>{
                this.renderCompanyIntroduction(commandData);
            });
        },
        renderCompanyIntroduction(commandData) {
            let width = this.$refs.refCompanyIntroductionHolder.clientWidth;
            this.$refs.refCompanyIntroductionTable.TableChart(commandData.data.data,{
                width: width,
                height: width * 0.77,
                marginBottom:100,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
            this.updateDate = commandData.data.date
        },
/*=====================个股百科-净利润行业对比=========================*/
        handleNetProfitCompare(commandData){
            this.isNetProfitCompareVisible = true;                      
            this.currentData = commandData.data.data;
            this.$nextTick(()=>{
                this.renderNetProfitCompare(commandData);
            });
        },
        renderNetProfitCompare(commandData) {           
            //展示legend
            this.$refs.refNetProfitCompareLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["bar"],
                colors: ["#FFC100"],
            });
            let width = this.$refs.refNetProfitCompareHolder.clientWidth;
            this.$refs.refNetProfitCompareTableBar.tableBarChart(commandData.data.data.slice(1), {
            width: width,
            height: width * 0.78,
            marginLeft: 220,
            marginRight: 200,
            marginTop: 75,
            marginBottom: 0,
            duration: 800,
            delay: 60,
            ease: "easeQuad",
            isNegativeSameDirection: true,
            yPadding: 0.3,
            numberPosition: "inside",
            // value1Suffix: ['vSecList', 'vJgqcList', 'vYzbyList'].indexOf(commandData.type) !== -1 ? '亿' : '万',
            value1Suffix: '',
            value2Suffix: '',
            title: ['', '', commandData.data.data[0].value2],
        });
        },


/*=====================个股百科-关系=========================*/
        handleRelationship(commandData){
            this.isRelationshipVisible = true;            
            this.currentData = commandData.data;
            this.$nextTick(()=>{
                this.renderRelationship(commandData);
            });
        },
        renderRelationship(commandData) { 
            let width = this.$refs.refRelationshipHolder.clientWidth;
            this.$refs.refRelationshipTable.TableChart(commandData.data.data,{
                width: width,
                height: width * 0.25,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });            
            this.updateDate = commandData.data.date
        },

/*=====================个股百科-股本结构========================*/
        handleCapitalStructure(commandData){
            this.isCapitalStructureVisible = true;
            this.$nextTick(()=>{
                this.renderCapitalStructure(commandData);
            });
        },
        renderCapitalStructure(commandData) {
            //展示legend
            this.$refs.refCapitalStructureLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["dot","dot"],
                colors: ["#FF7700", "#65BBFF"],
            });
            let width = this.$refs.refCapitalStructureHolder.clientWidth;
            this.$refs.refCapitalStructureLine.doubleYLinesChart(commandData.data.data,{
                width: width,
                height: width * 0.5,
                marginTop: 30,
                marginBottom: 50,
                marginLeft: 80,
                marginRight: 80,
                duration: 2000,
                delay: 40,
                ease: "easeCubicInOut",                
                curveName :"curveMonotoneX",                
                isShowTxtTips:true,
                numberSuffix:["","万股"],
                dotCircleRadius:9,
                xTickNumber :2,
                lineColors:["#FF7700","#65BBFF"],

            });
            this.$refs.refCapitalStructureTable.TableChart(commandData.data.tableData,{
                width: width,
                height: width * 0.2,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
            this.updateDate = commandData.data.date
        },
/*=====================个股百科-主力持仓========================*/
        handleMainHolder(commandData){
            this.isMainHolderVisible = true;
            this.$nextTick(()=>{
                this.renderMainHolder(commandData);
            });
        },
        renderMainHolder(commandData) {
            //展示legend
            this.$refs.refMainHolderLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["dot","dot"],
                colors: ["#FF4A40", "#65BBFF"],
            });
            let width = this.$refs.refMainHolder.clientWidth;
            
            let bar = []
            commandData.data.data.map((item,index)=>{
                bar.push(item.bar)
            })
            this.$refs.refMainHolderBarLine.doubleYBarLineChart(commandData.data.data,{
                width: width,
                height: width * 0.5,
                unit:'%',
                marginLeft:-20,
                marginRight:130,
                marginTop: 40,
                marginBottom:60,
                duration: 1000,
                xPadding: 0.5,
                ease: "easeCubicIn",
                dotCircleRadius:9,
                xTickNumber: 3, 
                yTickNumber: 6, 
                isShowTxtTips:true,               
                yDomain1:[0,Math.max.apply(Math,bar)],
            });
            this.$refs.refMainHolderTable.TableChart(commandData.data.tableData,{
                width: width,
                height: width * 0.2,
                marginTop:0,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
            this.updateDate = commandData.data.date
        },
/*=====================个股百科-收入和利润情况=========================*/ 
        handleIncomeProfit(commandData){
            
            this.isIncomeProfitVisible = true;  
            if(commandData.data.desc!=undefined){
                this.isTitleDesc= true;
            }                   
            this.currentData = commandData.data;
            this.$nextTick(()=>{
                this.renderIncomeProfit(commandData);
            });
        },   
        
        renderIncomeProfit(commandData){            
            //展示legend
            this.$refs.refIncomeProfitLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["bar","bar","bar","bar"],
                colors: ["#FF5A37", "#FFC100","#E5E5E5","#C7A375"],
            });
            let width = this.$refs.refIncomeProfitHolder.clientWidth; 
            this.$refs.refIncomeProfitBar.BarChart(commandData.data.data,{
                
                x: (d) => d.label,
                width: width,
                height: width * 0.65,
                marginLeft: 80,
                marginRight: 40,
                marginBottom: 100,
                marginTop: 20,
                zPadding: 0.05,
                duration: 800,
                delay: 400,
                yPadding: 0.95,
                ease: "easeBack",
                xTickNumber: 3,
                yTickNumber: 5,
                isFromZero: true,
                colors:["#FF5A37", "#FFC100","#E5E5E5","#C7A375"],
            });
        },
/*=====================个股百科-资产负债情况=========================*/ 
        handleAssetsDebt(commandData){
            
            this.isAssetsDebtVisible = true; 
            if(commandData.data.desc!=undefined){
                this.isTitleDesc= true;
            }                   
            this.currentData = commandData.data;
            this.$nextTick(()=>{
                this.renderAssetsDebt(commandData);
            });
        },   
        
        renderAssetsDebt(commandData){            
            //展示legend
            this.$refs.refAssetsDebtLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["bar","bar","bar","bar"],
                colors: ["#FF5A37", "#FFC100","#E5E5E5","#C7A375"],
            });
            let width = this.$refs.refAssetsDebtHolder.clientWidth; 
            this.$refs.refAssetsDebtBar.BarChart(commandData.data.data,{
                
                x: (d) => d.label,
                width: width,
                height: width * 0.65,
                marginLeft: 80,
                marginRight: 40,
                marginBottom: 100,
                marginTop: 50,
                zPadding: 0.05,
                duration: 800,
                delay: 400,
                yPadding: 0.95,
                ease: "easeBack",
                xTickNumber: 3,
                yTickNumber: 5,
                isFromZero: true,
                colors:["#FF5A37", "#FFC100","#E5E5E5","#C7A375"],                
                isShowText: true,
            });
        },
/*=====================个股百科-行业规模=========================*/ 
        handleIndustryScale(commandData){
            
            this.isIndustryScaleVisible = true;
            this.currentData = commandData;
             
            if(commandData.data.desc!=undefined){
                this.isTitleDesc= true;
            }
            if(commandData.data.desc1!=undefined){
                this.isDesc= true;
            }
            this.$nextTick(()=>{
                this.renderIndustryScale(commandData);
            });
        },   
        renderIndustryScale(commandData){
            let width = this.$refs.refIndustryScaleHolder.clientWidth; 
            this.$refs.refIndustryScaleBar.BarChart(commandData.data.data,{
                
                x: (d) => d.label,
                y: (d) => d.value,
                width: width,
                height: width * 0.65,
                marginLeft: 80,
                marginRight: 25,
                marginTop: 40,
                marginBottom: 40,
                xPadding: 0.4,
                duration: 800,
                delay: 80,
                ease: "easeCubic",
                isFromZero: true,
                isShowNumber: false,
                xTickNumber:10,
            });
        },
/*=====================个股百科-员工人数和营业收入=========================*/ 
        handleStaffCountAndIncome(commandData){
            
            this.isStaffCountAndIncomeVisible = true;
            this.$nextTick(()=>{
                this.renderStaffCountAndIncome(commandData);
            });
        },   
        renderStaffCountAndIncome(commandData){
            
            //展示legend
            this.$refs.refStaffCountAndIncomeLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["dot","dot"],
                colors: ["#FFC100", "#65BBFF"],
            });
            let width = this.$refs.refStaffCountAndIncomeHolder.clientWidth; 
            this.$refs.refStaffCountAndIncomeDoubleYLines.doubleYLinesChart(commandData.data.data,{
                width: width,
                height: width * 0.68,
                marginTop: 50,
                marginBottom: 50,
                marginLeft: 135,
                marginRight: 110,
                duration: 1000,
                delay: 40,
                ease: "easeCubicInOut",
                dotCircleRadius:8,
                isShowTxtTips:false,
                xTickNumber : 3,
            });
        },
/*=====================个股百科-员工分析=========================*/ 
        handleStaffAnalyze(commandData){
            
            this.isStaffAnalyzeVisible = true;
            if(commandData.data.desc1!=undefined){
                this.isDesc= true;
            }
            this.currentData = commandData.data;
            this.$nextTick(()=>{
                this.renderStaffAnalyze(commandData);
            });
        },   
        renderStaffAnalyze(commandData){
            let width = this.$refs.refStaffAnalyzeHolder.clientWidth;
            this.$refs.refStaffAnalyzeRadar.RadarChart(commandData.data.data,{                
                x: (d) => d.label,
                width: width,
                height: width * 0.5,
                marginTop:40,
                marginLeft: 80,
                marginRight: 80,
                marginBottom: 40,
                delay: 100,
                margins: { top: 0, left: 0, bottom: 0, right: 0 },
                textColor: "black",
                radius: 100,
                duration: 1000,
                tickNum: 3, //几层多边形
                lineColors: ["#FF5A37"],
                lineWidth: ["2", "2"],
                axisFillColor: ["white","white"], //每一层的颜色
                axisStrokeColor: "#707070", //对角线的颜色
                pointsColor: "white", //每个点的颜色
                pointsSize: 0, //每个点的size
            });
        },


        
/*=====================个股百科-人均营业收入=========================*/ 
        handleIncomeAve(commandData){            
            this.isIncomeAveVisible = true; 
            if(commandData.data.desc1!=undefined){
                this.isDesc= true;
            }          
            this.currentData = commandData.data
            this.$nextTick(()=>{
                this.renderIncomeAve(commandData);
            });
        },   
        renderIncomeAve(commandData){
            
            //展示legend
            this.$refs.refIncomeAveLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["dot","dot"],
                colors: ["#FFC100", "#65BBFF"],
            });
            let width = this.$refs.refIncomeAveHolder.clientWidth; 
            this.$refs.refIncomeAveLine.LineChart(commandData.data.data,{
                label: (d) => d.label,
                width: width,
                height: width * 0.62,
                marginLeft: 100,
                marginRight:95,
                marginTop: 50,
                marginBottom: 50,
                duration: 900,
                delay: 50,
                ease: "easeCubic",
                dotCircleRadius: 8,
                isShowTxtTips: false,
                isFromZero: false,
                yTickNumber: 6,
            });
        },
/*=====================个股百科-博士员工人数=========================*/ 
        handleDoctorData(commandData){
            
            this.isDoctorDataVisible = true;
            this.currentData = commandData.data
            this.$nextTick(()=>{
                this.renderDoctorData(commandData);
            });
        },   
        renderDoctorData(commandData){
            let width = this.$refs.refDoctorDataHolder.clientWidth; 
            this.$refs.refDoctorDataBar.BarChart(commandData.data.data,{
                
                x: (d) => d.sdate,
                y: (d) => d.iSatffCount,
                width: width,
                height: width * 0.6,
                marginLeft: 60,
                marginRight: 25,
                marginTop: 50,
                marginBottom: 40,
                xPadding: 0.4,
                duration: 800,
                delay: 80,
                ease: "easeCubic",
                isFromZero: true,
                isShowNumber: true,
                numberSuffix:'人'
            });
        },
/*=====================个股百科-员工学历构成=========================*/ 
        handleStaffEducation(commandData){
            
            this.isStaffEducationVisible = true;  
            this.$nextTick(()=>{
                this.renderStaffEducation(commandData);
            });
        },   
        
        renderStaffEducation(commandData){            
            //展示legend
            this.$refs.refStaffEducationLegend.createLegend({
                legend: commandData.data.legend,
                legendType: ["bar","bar","bar","bar","bar","bar","bar"],
                colors: ["#FFC100", "#FF7700","#E5E5E5","#FF4A40","#178CEA","#FF64C1","#2BFFFF"],
            });
            let width = this.$refs.refStaffEducationHolder.clientWidth; 
            this.$refs.refStaffEducationBar.StackedBarChart(commandData.data.data,{
                
                x: (d) => d.label,
                width: width,
                height: width * 0.68,
                marginLeft: 110,
                marginRight: 40,
                marginTop: 40,
                marginBottom: 50,
                xPadding: 0.6,
                duration: 800,
                delay: 80,
                ease: "easeCubic",
                colors: ["#FFC100", "#FF7700","A1A1A1","#FF4A40","#178CEA","#FF64C1","#2BFFFF"],
            });
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>