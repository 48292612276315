<template>
    <div :class="customClass">
        <div class="vYzxsList" v-if="['vYzxsList'].indexOf(type) !== -1">
            <div v-for="(item, index) in data" :key="index" class="plate_item">
                <div>
                    <span>{{ item.sSaleDepNameA }}</span>
                    <div class="line vline"></div>
                    <div class="ball vball">
                        <span>{{ item.sSecName }}</span>
                        <span>{{ item.fChangePct.toFixed(2) }}%</span>
                    </div>
                    <div class="line vline"></div>
                    <span>{{ item.sSaleDepNameB }}</span>
                </div>
                <p>年内携手<span>{{ item.sXsCount }}</span>次，成功率<span>{{ item.fSuccessPct }}%</span>，净买{{ item.fIncome.toFixed(2) }}万</p>
            </div>
        </div>
        <div class="vlist" v-if="['vCgslList', 'vZjqcList'].indexOf(type) !== -1">
            <div v-for="(item, index) in data" :key="index" class="plate_item">
                <div class="ball vball">
                    <span>{{ item.sSecName }}</span>
                    <span>{{ item.fChangePct.toFixed(2) }}%</span>
                </div>
                <div class="line vline">
                    <span>{{ item.sSaleDepName }}</span>
                </div>
                <div class="msg">
                    <span>普通游资</span>
                    <span>3日跟买成功率</span>
                    <span>{{ item.fThreeDaySuccess }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
export default {
    props: ['customClass'],
    data: function() {
        return {
            type: '',
            data: [],
        }
    },
    methods: {
        init(data) {
            this.type = data.type
            this.data = data.data.data.data
            this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1000,
                    delay: 500,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();
            })
        }
    },
}
</script>
