<!-- 客群管理 -->
<template>
  <div
    :class="{
      holder: true,
      debugging_window: true,
      is_landscape: isLandscape,
      is_show_pc: isShowPc,
    }"
  >
    <!--debugging_window-->

    <!-- logo -->
    <div :class="{ logo: true, window_sit_top: customWindowClass == 'window_sit_top' }">
      <logo-switcher ref="refLogoSwitcher" />
    </div>

    <!-- 背景视频 -->
    <div class="broadcast_bg_media">
      <media-switcher :isLoop="true" ref="refBroadcastBgMedia" />
    </div>

    <!-- 全屏图片 -->
    <div class="full_media">
      <media-cover ref="refFullMedia" />
    </div>

    <!-- 窗口个性化图表 -->
    <div
      :class="{
        window: true,
        window_sit_left: customWindowClass == 'window_sit_left',
        window_sit_top: customWindowClass == 'window_sit_top',
        theme_white: !isThemeBlack,
        window_visible: isWindowVisible,
      }"
    >
      <chart-switcher ref="refChartSwitcher" :subComponentName="subComponentName" />
      <div class="window_reflection"></div>
    </div>

    <!-- 窗口媒体-图片视频 -->
    <div :class="`window_media ${customWindowClass} `">
      <div class="window_media_inside">
        <media-switcher :isFront="true" ref="refWindowMedia" />
      </div>
    </div>

    <div :class="`window_media ${customWindowClass} `">
      <div class="window_media_inside">
        <!-- iframe滚动窗口 -->
        <iframe-scroller ref="refWindowIframe" />
      </div>
    </div>

    <!-- 前景滚动栏 -->
    <market-marquee ref="refMarketMarquee" />

    <!-- 文字滚动栏 -->
    <!-- <text-marquee ref="refTextMarquee" /> -->

    <!-- 多功能底部栏 -->
    <multi-func-bar ref="refMultiFuncBar" :class="{ window_sit_top: customWindowClass == 'window_sit_top' }" />

    <!--粒子特效-->
    <snow-flakes ref="refSnowFlakes" />
  </div>
</template>

<script>
import { sleep, setRem } from "@/utils/utils.js";
import marketMarquee from "@/businessComp/marketMarquee/index.vue";
import textMarquee from "@/businessComp/textMarquee/index.vue";
import chartSwitcher from "@/businessComp/chartSwitcher/index.vue";
import mediaSwitcher from "@/businessComp/mediaSwitcher/index.vue";
// import imageScroller from "@/businessComp/imageScroller/index.vue";
import iframeScroller from "@/businessComp/iframeScroller/index.vue";
import mediaCover from "@/businessComp/mediaCover/index.vue";
import logoSwitcher from "@/businessComp/logoSwitcher/index.vue";
import snowFlakes from "@/businessComp/snowFlakes/index.vue";
import multiFuncBar from "@/businessComp/multiFuncBar/index.vue";

let chatSocket;
export default {
  data() {
    return {
      isFirstConnect: true, //是否为第一次连接，默认true
      isLandscape: false, //默认横屏模式
      remLandscape: 1920, //横屏时的设计rem
      remPortrait: 1000, //竖屏时的设计rem， 招姐为1400，普通时为1200
      userId: 0,
      isThemeBlack: false,
      isWindowVisible: false, //默认window是隐藏的
      customWindowClass: "window_sit_top", //自定义的window类，window_sit_left,window_sit_top
      isShowPc: false,
      subComponentName: '',
    };
  },
  components: {
    marketMarquee,
    textMarquee,
    chartSwitcher,
    mediaSwitcher,
    iframeScroller,
    mediaCover,
    logoSwitcher,
    snowFlakes,
    multiFuncBar,
  },
  methods: {
    createSocket() {
      // const path = "ws://192.168.51.188:8888/ws/chat/";
      const path = "wss://ws.wedengta.com/ws/livestream/";
      try {
        chatSocket = new WebSocket(path + this.userId + "/");
      } catch (e) {
        // Notify({ type: "danger", message: "长连接建立失败！" });
      }
      //建立成功
      chatSocket.onopen = (e) => {
        //通知控制端我上线了
        this.sendCommand("firstAnswer");
        //开启监听广播消息
        this.startMonite();
      };

      chatSocket.onerror = (e) => {
        // Notify({ type: "danger", message: "长连接出错" });
      };
    },
    //发送命令
    sendCommand(command, content) {
      chatSocket.send(
        JSON.stringify({
          eventType: command,
          content: content,
        })
      );
    },
    //接收消息
    startMonite() {
      chatSocket.onmessage = async (e) => {
        const data = JSON.parse(e.data);

        //心跳回答，不然控制端就会认为接收端死了
        if (data.componentName == "firstAsk") {
          this.sendCommand("firstAnswer");
          return;
        }
        if (data.componentName == "isBackOnline") {
          this.sendCommand("backOnline");
          return;
        }

        //连接，心跳类通讯不进入命令处理环节
        if (["firstAsk", "firstAnswer", "isBackOnline", "backOnline"].indexOf(data.eventType) > -1) {
          return;
        }
        //处理命令
        this.handleCommand(data);
      };
    },
    //处理命令
    async handleCommand(data) {
      if (data.componentName == undefined) {
        //do nothing
      } else if (data.componentName == "reload") {
        location.reload();
      } else if (data.componentName == "togglePc") {
        this.isShowPc = data.componentCommandData == "showPc";
      } else if (data.componentName == "broadcastBgMedia") {
        //直播间背景
        this.$refs.refBroadcastBgMedia[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "theme") {
        //窗口皮肤
        this.isThemeBlack = data.componentCommandData;
      } else if (data.componentName == "screenMode") {
        //横屏还是竖屏
        console.log("data.componentCommandData", data.componentCommandData);
        this.isLandscape = data.componentCommandData == "landscape";
        this.customWindowClass = this.isLandscape ? "window_sit_left" : "window_sit_top";
        setRem(this.isLandscape ? this.remLandscape : this.remPortrait);
      } else if (data.componentName == "logoSwitcher") {
        //logo
        this.$refs.refLogoSwitcher[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "snowFlakes") {
        //粒子系统。下红包之类
        this.$refs.refSnowFlakes[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "fullMedia") {
        //全屏图片视频
        this.$refs.refFullMedia[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "windowMedia") {
        //窗口图片视频
        this.$refs.refWindowMedia[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "windowIframe") {
        //窗口-iframe
        this.$refs.refWindowIframe[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "audioSwitcher") {
        //全局音频
        this.$refs.refFullAudio[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "marketMarquee") {
        //底部市场滚动条
        this.$refs.refMarketMarquee[data.componentCommand](data.componentCommandData);
      } else if (data.componentName == "multiFuncBar") {
        //底部综合条
        this.$refs.refMultiFuncBar[data.componentCommand](data.componentCommandData);
      } else if (data.componentName.indexOf("chartSwitcher") > -1) {
        this.subComponentName = data.componentName.split("|")[1];
        this.$nextTick(async () => {
          //关闭图表
          if (data.componentCommandData != undefined && data.componentCommandData.isRefresh) {
            //如果发现
          } else {
            this.$bus.$emit("chartExit");
            await sleep(300);
            this.isWindowVisible = false;
            await sleep(400);
          }
          //开启图表
          if (data.componentCommand != "hide") {
            this.isWindowVisible = true;
            this.$refs.refChartSwitcher.subComponent(data.componentCommand, data.componentCommandData);
          }
        })
      }
    },
  },
  async mounted() {
    //userId是后台给的，目前自己随便写
    this.userId = this.$route.query.userId;
    this.createSocket();
  },
};
</script>

<style lang="less" scoped>
@import url("index.less");
@import url("theme_white.less");
// @import url("zhaojie.less");
</style>
