<template>
    <div :id="id" :class="['plateBlock', customClass]">
        <ul>
            <li class="plate_item" v-for="(item, index) in data" :key="index">
                <div :class="getClass(item.fBuyValue)">
                    <div>{{ item.sPlate }}</div>
                    <div class="num">{{ item.sBuyValue }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
export default {
    props: [ "id","customClass"],
    data: function() {
        return {
            data: []
        }
    },
    methods: {
        getClass(value) {
            if(value >= 5e+09){
                return 'rise_lev4';
            }else if(value >= 3e+09 && value < 5e+09){
                return 'rise_lev3';
            }else if(value >= 1e+09 && value < 3e+09){
                return 'rise_lev2';
            }else{
                return 'rise_lev1';
            }
        },
        async PlateBlock(data, {
            width = 520,
            height = 340,
        }) {
            this.data = data
            document.getElementById(this.id).style.cssText = `width: ${width}px;height: ${height}px;`
            this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1100,
                    delay: 160,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();
            })
        }
    }
}
</script>
<style lang="less" scoped>
.plateBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 25%;
            > div {
                margin: 3px;
                color: #fff;
                padding: 18px 0;
                text-align: center;
                line-height: 1.1;
                font-size: 22px;
                vertical-align: baseline;
                .num {
                    font-size: 26px;
                    margin-top: 8px;
                    font-weight: bold;
                }
            }
            .rise_lev4 {
                background-color: #e64b4b;
            }
            .rise_lev3 {
                background-color: #ff7676;
            }
            .rise_lev2 {
                background-color: #ffcaca;
                color: #e64b4b;
            }
            .rise_lev1 {
                background-color: #ffebeb;
                color: #e64b4b;
            }
        }
    }
}
</style>
