<template>
  <div>
    <div class="pages hidden" id="financeAnalysis">
      <page-title id="financeAnalysisTxt" ref="refPageTitle" />
      <div class="update_date hidden" id="updateDate"></div>
      <div class="chart_row" v-if="isChartVisible">
        <div class="chart_legend">
          <chart-legend ref="refDiagnosisValueLegend1" id="refDiagnosisValueLegend1" class="common_legend" />
          <chart-legend ref="refDiagnosisValueLegend2" id="refDiagnosisValueLegend2" class="common_legend" />
        </div>
        <div ref="refDiagnosisValueHolder" class="chart_col">
          <bar-chart ref="refDiagnosisValueChart1" id="refDiagnosisValueChart1" customClass="bar_chart" />
        </div>
        <div class="chart_col">
          <line-chart ref="refDiagnosisValueChart2" id="refDiagnosisValueChart2" customClass="line_chart" />
        </div>
      </div>
      <div class="chart_desc hidden" id="chartDesc"></div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import barChart from "@/components/barChart/index_v1.0.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import chartLegend from "@/components/chartLegend/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      colorArr1: ["#f49117", "#ff6a00"],
      colorArr2: ["#178CEA", "#1dc671"],
      isChartVisible: false,
    };
  },
  components: {
    pageTitle,
    barChart,
    lineChart,
    chartLegend,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.$refs.refPageTitle) {
          this.$refs.refPageTitle.hide();
        }
        await sleep(200);
        $("#financeAnalysis #updateDate").addClass("hidden");
        $("#financeAnalysis #chartDesc").addClass("hidden");
        this.isChartVisible = false;
        resolve();
      });
    },
    async show(commandData) {
      this.isChartVisible = false;
      $("#financeAnalysis").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);


      //日期，描述
      $("#financeAnalysis #updateDate").html(commandData.data.date);
      $("#financeAnalysis #chartDesc").html(commandData.data.desc);

      this.$nextTick(async () => {
        await sleep(200);

        $("#financeAnalysis #updateDate").removeClass("hidden");
        this.updateDateHandler = anyElement({
          targets: document.querySelector("#financeAnalysis #updateDate"),
          isSplitTxt: false,
          duration: 900,
          delay: 50,
          easing: "easeOutExpo",
          inEffect: "rightIn",
          outEffect: "leftOutBig",
        });
        this.updateDateHandler.runIn();

        this.handleDiagnosisValue(commandData);

        $("#financeAnalysis #chartDesc").removeClass("hidden");
        this.chartDescHandler = anyElement({
          targets: document.querySelector("#financeAnalysis #chartDesc"),
          isSplitTxt: true,
          duration: 280,
          delay: 15,
          easing: "easeOutExpo",
          inEffect: "bottoIn",
          outEffect: "leftOutBig",
        });
        await sleep(20);
        this.chartDescHandler.runIn();
      });
    },
    handleDiagnosisValue(commandData) {
      this.isChartVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
        this.renderLineChart(commandData);
      });
    },
    renderTableChart(commandData) {
      //展示legend
      this.$refs.refDiagnosisValueLegend1.createLegend({
        legend: commandData.data.legend1,
        legendType: ["bar", "dot_line"],
        colors: this.colorArr1,
      });

      let width = this.$refs.refDiagnosisValueHolder.clientWidth;
      this.$refs.refDiagnosisValueChart1.BarChart(commandData.data.data.chartData, {
        x: (d) => d.label,
        y: (d) => d.bar,
        aveLine: commandData.data.data.ave,
        width: width,
        height: width * 1.1,
        marginLeft: 50,
        marginRight: 25,
        marginTop: 50,
        marginBottom: 50,
        xPadding: 0.3,
        duration: 800,
        delay: 80,
        ease: "easeCubic",
        isFromZero: true,
        isShowNumber: true,
      });
    },
    renderLineChart(commandData) {
      //展示legend
      this.$refs.refDiagnosisValueLegend2.createLegend({
        legend: commandData.data.legend2,
        legendType: ["line", "line"],
        colors: this.colorArr2,
      });
      let width = this.$refs.refDiagnosisValueHolder.clientWidth;
      this.$refs.refDiagnosisValueChart2.LineChart(commandData.data.data.chartData, {
        label: (d) => d.label,
        width: width,
        height: width * 1.1,
        marginLeft: 90,
        marginRight: 30,
        marginTop: 50,
        marginBottom: 50,
        duration: 900,
        delay: 50,
        ease: "easeCubic",
        dotCircleRadius: 8,
        isShowTxtTips: false,
        isFromZero: false,
        yTickNumber: 6,
      });
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;

    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
