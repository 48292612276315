<template>
  <div :class="`multi_func_bar ${theme}`" v-if="isShow">
    <div class="title">{{ title }}</div>
    <img :src="logo" class="logo" />
    <div class="live_time">{{ liveTime }}</div>
    <div class="live_date">{{ liveDate }}</div>
    <div class="risk_free">{{ riskFree }}</div>
    <div class="marquee text_marquee" id="textMarquee">
      <div class="marquee-inner">
        <div class="marquee-content">
          <div class="marquee-text">
            {{ marqueeText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import InfiniteMarquee from "infinite-marquee";
export default {
  props: [],
  data() {
    return {
      theme:'', //皮肤
      title: "每周回顾每周回顾每周回顾", //直播间大标题
      logo: require("@/businessComp/multiFuncBar/avatar.png"), //直播间logo
      liveTime: "[直播时间] 交易日 15:50-15:45", //直播时间
      liveDate: "2022.04.22", //直播日期
      riskFree: "本内容仅供参考，不构成投资建议。股市有风险，投资需谨慎。", //风险提示
      marqueeText: "职业证书编号: **************", //投顾老师id
      isShow: false,
    };
  },
  methods: {
    show(options) {
      this.isShow = true;
      this.theme = options.theme;
      this.title = options.title;
      this.logo = options.logo;
      this.liveTime = options.liveTime;
      this.liveDate = this.$moment().format("YYYY-MM-DD");
      this.riskFree = options.riskFree;
      this.marqueeText = options.marqueeText;
      this.$nextTick(() => {
        this.initTextMarquee();
      });
    },
    hide() {
      this.isShow = false;
    },
    initTextMarquee() {
      new InfiniteMarquee({
        el: document.querySelector("#textMarquee"),
        direction: "left",
        duration: 40,
        css: true,
      });
    },
  },
  // async mounted() {
  //   this.initTextMarquee();
  // },
};
</script>
<style lang="less" scoped>
@import "./index.less";
@import "infinite-marquee/assets/css/infinite-marquee";
</style>
