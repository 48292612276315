<template>
  <div id="bigTradeChart">
    <div class="pages hidden" id="bigTradePages">
      <page-title id="bigTradeChartText" ref="refPageTitle"></page-title>
      <div class="page_date">{{ updateDate }}</div>

      <div ref="refBigTradeHolder" v-if="isBigTradeVisible">        
        <chart-legend ref="refBigTradeDoubleLegend" id="bigTradeDoubleLegend" class="big_trade_double_legend" />
        <double-y-bar-line-chart ref="refBigTradeDouble" id="bigTradeDouble" customClass="big_trade_double_chart" />
        <table-chart ref="refBigTradeTable" id="bigTradeTable" customClass="big_trade_table_chart" />
      </div>

      <div ref="refDetailTableHolder" v-if="isDetailTableVisible">
        <table-chart ref="refDetailTable" id="detailTable" customClass="detail_table_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import doubleYBarLineChart from "@/components/marketChart/doubleYBarLineChart_v2.0.vue";
import chartLegend from "@/components/chartLegend/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      updateDate:'', //
      isBigTradeVisible: false, //大宗交易
      isDetailTableVisible:false, //大宗交易明细
    };
  },
  components: {
    pageTitle,
    tableChart,
    doubleYBarLineChart,
    chartLegend,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        this.isBigTradeVisible = false;
        this.isDetailTableVisible = false;
        this.updateDate = "";        
        resolve();
      });
    },
    async show(commandData) {
      this.isBigTradeVisible = false;      
      this.isDetailTableVisible = false;
      $("#bigTradePages").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);
      this.$nextTick(async () => {
        await sleep(300);
        if(["成交量", "溢价率"].indexOf(commandData.type) > -1){          
          this.handleDetail(commandData);
        } else if (commandData.type == "大宗交易"){
          
          this.handleBigTradeDouble(commandData);
          this.handleBigTradeTable(commandData);
        }
      });
    },
    handleDetail(commandData) {
      this.isDetailTableVisible = true;
      this.$nextTick(() => {
        this.renderDetailTableChart(commandData);
      });
    },
    renderDetailTableChart(commandData) {
      let width = this.$refs.refDetailTableHolder.clientWidth;
      this.$refs.refDetailTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
      this.updateDate = commandData.data.date;
    },
    
    handleBigTradeDouble(commandData) {
      this.isBigTradeVisible = true;
      this.$nextTick(() => {
        this.renderBigTradeDoubleChart(commandData);
      });
    },
    renderBigTradeDoubleChart(commandData){
      //展示legend
      this.$refs.refBigTradeDoubleLegend.createLegend({
        legend: commandData.data.legend,
        legendType: ["bar","dot"],
        colors: ["#FFC100", "#178CEA"],
      });
      let width = this.$refs.refBigTradeHolder.clientWidth;
      let bar = []
      commandData.data.data.map((item,index)=>{
        bar.push(item.bar)
      })
      this.$refs.refBigTradeDouble.doubleYBarLineChart(commandData.data.data, {
        width: width,
        height: width * 0.35,
        marginLeft: 40,
        marginRight:80,
        marginTop: 40,
        marginBottom:40,
        yDomain1:[0,Math.max.apply(Math,bar)],
        duration: 1000,
        xPadding: 0.3,
        ease: "easeCubicIn",
        dotCircleRadius:0,
        xTickNumber: 3,
        // ease: "easeCubicInOut",
      });     
    },

    handleBigTradeTable(commandData) {
      this.isBigTradeVisible = true;
      this.$nextTick(() => {
        this.renderBigTradeTableChart(commandData);
      });
    },
    renderBigTradeTableChart(commandData) {
      let width = this.$refs.refBigTradeHolder.clientWidth;
      this.$refs.refBigTradeTable.TableChart(commandData.data.tabledata, {
        width: width,
        height: width * 0.4,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
      this.updateDate = commandData.data.date;
    },



  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>