<template>
  <div id="quote">
    <div class="pages hidden" id="quotePages">
      <div class="page_title"><span class="text hidden" id="quoteText"></span></div>
      <div class="page_date">{{ updateDate }}</div>

      <div ref="refTradeChartHolder" v-if="isTradeChartVisible">
        <p class="page_desc">{{ desc }}</p>
        <self-line-chart ref="refTradeChart" id="tradeChart" customClass="link_chart" />
      </div>

      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>

      <div ref="refPlateBlockHolder" v-if="isPlateBlockVisible">
        <plate-block ref="refPlateBlock" id="plateBlock" customClass="plate_block" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import tableChart from "@/components/tableChart/index.vue";
import selfLineChart from "@/components/selfLineChart/index.vue";
import plateBlock from "./plateBlock.vue"
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      desc: '',  // 
      isTradeChartVisible: false,  // 融资融券
      isQuoteTableVisible: false,  // 个股表格
      isPlateBlockVisible: false,  // 板块
    };
  },
  components: {
    tableChart,
    selfLineChart,
    plateBlock
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#quoteText").addClass("hidden");
        this.isTradeChartVisible = false;
        this.isQuoteTableVisible = false;
        this.isPlateBlockVisible = false;
        this.updateDate = "";
        this.desc = "";
        resolve();
      });
    },
    async show(commandData) {
      this.isTradeChartVisible = false;
      this.isQuoteTableVisible = false;
      this.isPlateBlockVisible = false;

      //设置标题
      const title = commandData.data.pageTitle;
      $("#quotePages").removeClass("hidden");
      $("#quoteText").html(title);

      this.$nextTick(async () => {
        $("#quoteText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#quoteText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.type === 'IndustryPlateDtMargin' || commandData.type === 'vtIndustryBuyValue' || commandData.type === 'vtPlateBuyValue') {
          this.handleQuote(commandData)
        } else if (commandData.type === 'vtSHMarginTrade' || commandData.type === 'vtSZMarginTrade') {
          this.handleTradeChart(commandData)
        } else {
          this.handlePlateBlock(commandData)
        }
      });
    },
    handleTradeChart(commandData) {
      this.isTradeChartVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderTradeChart(commandData)
      });
    },
    renderTradeChart(commandData) {
      this.updateDate = commandData.data.date;
      this.desc = commandData.data.data?.desc[commandData.type] || ''
      let width = this.$refs.refTradeChartHolder.clientWidth;
      let data = commandData.data.data[commandData.type].map((item) => {
        return {
          name: item.name,
          value: Number((item.value / 100000000).toFixed(1))
        }
      })
      this.$refs.refTradeChart.selfLineChart(data, {
        width: width,
        height: width * 0.7,
        marginLeft: 0,
        marginRight: 110,
        marginTop: 60,
        marginBottom: 40,
        duration: 1500,
        delay: 20,
        unit: '亿'
      });
    },
    
    handleQuote(commandData) {
      this.isQuoteTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col2Suffix: "%",
        col3Suffix:
          ["quoteUpTrend", "quoteDownTrend"].indexOf(commandData.type) > -1 ? "%" : "",
      });
      this.updateDate = commandData.data.date;
    },
    handlePlateBlock(commandData) {
      this.isPlateBlockVisible = true;
      this.$nextTick(() => {
        this.renderPlateBlock(commandData);
      });
    },
    renderPlateBlock(commandData) {
      let width = this.$refs.refPlateBlockHolder.clientWidth;
      this.$refs.refPlateBlock.PlateBlock(commandData.data.data, {
        width: width,
        height: width * 0.77,
      });
      this.updateDate = commandData.data.date;
    },
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
