<template>
  <div class="charts_holder">
    <component
        v-bind:is="subComponentName"
        ref="refSubComponent"
    >
    </component>
  </div>
</template>
<script>
// import marketRaceChart from "@/businessComp/charts/marketRaceChart//index.vue";
import generalChart from "@/businessComp/charts/generalChart/index.vue";
import marketChart from "@/businessComp/charts/marketChart/index.vue";
import plateChart from "@/businessComp/charts/plateChart/index.vue";
import northFundChart from "@/businessComp/charts/northFundChart/index.vue";
import auctionChart from "@/businessComp/charts/auctionChart/index.vue";
import financeCompareChart from "@/businessComp/charts/financeCompareChart/index.vue";
import diagnosisValueChart from "@/businessComp/charts/diagnosisValueChart/index.vue";
import financeAnalysisChart from "@/businessComp/charts/financeAnalysisChart/index.vue";
import evaluatingDistChart from "@/businessComp/charts/evaluatingDistChart/index.vue";
import marginTradeChart from "@/businessComp/charts/marginTradeChart/index.vue";
import longHuChart from "@/businessComp/charts/longHuChart/index.vue";
import ipoInfoChart from "@/businessComp/charts/ipoInfoChart/index.vue";
import checkMarketChart from "@/businessComp/charts/checkMarketChart/index.vue";
import bigTradeChart from "@/businessComp/charts/bigTradeChart/index.vue";
import chipDistChart from "@/businessComp/charts/chipDistChart/index.vue";
import resultsContrastChart from "@/businessComp/charts/resultsContrastChart/index.vue";
import companyIntroChart from "@/businessComp/charts/companyIntroChart/index.vue";
import plateAreaChart from "@/businessComp/charts/plateAreaChart/index.vue";
import intelligentDiagnosisChart from "@/businessComp/charts/intelligentDiagnosisChart/index.vue";
import investmentMapChart from "@/businessComp/charts/investmentMapChart/index.vue";
import duPonChart from "@/businessComp/charts/duPonChart/index.vue";
import stocksFundsChart from "@/businessComp/charts/stocksFundsChart/index.vue";
import sharePledgeChart from "@/businessComp/charts/sharePledgeChart/index.vue";
import bsSignalChart from "@/businessComp/charts/bsSignalChart/index.vue";
import keChuangGraphChart from "@/businessComp/charts/keChuangGraphChart/index.vue";
import plateQuoteChart from "@/businessComp/charts/plateQuoteChart/index.vue";
import nuggetsChart from "@/businessComp/charts/nuggetsChart/index.vue";
import stockMineSweepChart from "@/businessComp/charts/stockMineSweepChart/index.vue";
import financingTrackChart from "@/businessComp/charts/financingTrackChart/index.vue";
import goodwillAnalysisChart from "@/businessComp/charts/goodwillAnalysisChart/index.vue";
import goodwillWarnChart from "@/businessComp/charts/goodwillWarnChart/index.vue";
import historyBackChart from "@/businessComp/charts/historyBackChart/index.vue";
import longHuStockChart from "@/businessComp/charts/longHuStockChart/index.vue";
import similarKChart from "@/businessComp/charts/similarKChart/index.vue";
import intelliChooseStockChart from "@/businessComp/charts/intelliChooseStockChart/index.vue";

export default {
  props: ['subComponentName'],
  data: function () {
    return {};
  },
  components: {
    // marketRaceChart,
    generalChart,
    marketChart,
    plateChart,
    northFundChart,
    marginTradeChart,
    auctionChart,
    financeCompareChart,
    diagnosisValueChart,
    financeAnalysisChart,
    evaluatingDistChart,
    longHuChart,
    ipoInfoChart,
    checkMarketChart,
    bigTradeChart,
    chipDistChart,
    resultsContrastChart,
 	  companyIntroChart,
    plateAreaChart,
    intelligentDiagnosisChart,
    investmentMapChart,
    duPonChart,
    stocksFundsChart,
    sharePledgeChart,
    bsSignalChart,
    keChuangGraphChart,
    nuggetsChart,
    plateQuoteChart,
    stockMineSweepChart,
    financingTrackChart,
    goodwillAnalysisChart,
    goodwillWarnChart,
    historyBackChart,
    longHuStockChart,
    similarKChart,
    intelliChooseStockChart,
  },
  methods: {
    subComponent(componentCommand, componentCommandData) {
      this.$refs.refSubComponent[componentCommand](componentCommandData);
    }
  },
};
</script>

