<template>
    <div id="stockMineSweepChart">
        <div class="pages hidden" id="stockMineSweepPages">
            <page-title id="stockMineSweepChartText" ref="refPageTitle"></page-title>
            <div ref="refStockMineSweepHolder" v-if="isStockMineSweepVisible" class="stock_mine_sweep">
                <div :class=classData>
                    <div class="seccode_info">
                        <span class="secname">{{ currentData.secname }}</span>
                        <span>{{ seccode }}</span>
                    </div>
                    <div v-if="isexist('sRiskLevelDesc')" class="seccode_risk">
                        <div class="risk_txt" :class='"risk"+currentData.data.data[0].iRiskLevel'>{{ currentData.data.data[0].sRiskLevelDesc}}</div>
                        <div class="risk_indicator" :class='"risk"+currentData.data.data[0].iMineNum'>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div v-if="vRLength(currentData.data.data[0])" class="contrast_msg">                    
                    <div>风险警示</div>
                    <ul>
                        <li v-for="item in currentData.data.data[0].vRiskItem" :key="item.sRiskName" class="plate_item">
                            <span class="riskName" :class='"item"+item.iTagColor'>{{ item.sRiskName }}</span>
                            <span class="riskDesc">{{ item.sRiskDesc }}</span>
                        </li>
                    </ul>                   
                </div>
                <ul v-else-if="isexist('sNoticeDay')" class="performance_risk">                    
                    <li><span>{{ currentData.data.data[0].sNoticeDay}}</span><span>业绩报告</span></li>
                    <li>{{ currentData.data.data[0].sNoticeResult}}</li> 
                    <li>{{ currentData.data.data[0].sNoticeDesc}}</li>                
                </ul>
                <div v-else-if="isexist('fTotalPledgeRatio') && currentData.data.data[0].fRiskScore!=0" class="pledge_chart">                    
                    <donut-chart ref="refDonutChart" id="refDonutChart" customClass="donut_chart"></donut-chart>
                    <ul>
                        <li>
                            <div class="icon icon1"></div>
                            <div class="text">
                                <span>{{ (currentData.data.data[0].fOffsetStockNum/currentData.data.data[0].fTotalPledgeStockNum*100).toFixed(2) }}%,</span>
                                <span> 约{{ currentData.data.data[0].fOffsetStockNum }}亿股,</span>
                                <span> 已到平仓线</span>
                            </div>
                        </li>
                        <li>
                            <div class="icon icon2"></div>
                            <div class="text">
                                <span>{{ (currentData.data.data[0].fEarlyWarningStockNum/currentData.data.data[0].fTotalPledgeStockNum*100).toFixed(2) }}%,</span>
                                <span> 约{{ currentData.data.data[0].fEarlyWarningStockNum }}亿股,</span>
                                <span> 已到预警线</span>
                            </div>
                        </li>
                        <li>
                            <div class="icon icon3"></div>
                            <div class="text">
                                <span>{{ (currentData.data.data[0].fUntouchWarningStockNum/currentData.data.data[0].fTotalPledgeStockNum*100).toFixed(2)}}%,</span>
                                <span> 约{{ currentData.data.data[0].fUntouchWarningStockNum }}亿股,</span>
                                <span> 未触及预警线</span>
                            </div>
                        </li>
                    </ul>
                    <div class="pledge_des">
                        <span>总质押{{ currentData.data.data[0].fTotalPledgeStockNum }}亿股,</span>
                        <span>占质押股东总持股比例{{ currentData.data.data[0].fTotalPledgeRatio }}%,</span>
                        <span>占总股本比例{{ currentData.data.data[0].fTotalEquityRatio }}%,</span>
                        <span>{{ currentData.data.data[0].sRiskDesc }}</span>
                    </div>
                </div>                 
                <div v-else-if="isexist('iDisClosureNum') && currentData.data.data[0].vItem.length!=0" class="closure_chart">
                    <div class="closure_desc">{{ currentData.data.data[0].sRiskDesc }}</div> 
                    <ul>
                        <li v-for="(item,index) in vItem" :key='index'>
                            <div class="title" :class='"title"+(index)+1'>{{item.sTitle}}</div>
                            <div class="sdate" :class='"sdate"+(index)+1'>{{item.sDate.slice(0,11)}}</div>
                        </li>                    
                    </ul>
                </div>  
               <div v-else-if="isexist('iLitigateCasesNum') && currentData.data.data[0].vItem.length!=0" class="closure_chart">
                    <div class="closure_desc">{{ currentData.data.data[0].sRiskDesc }}</div> 
                    <ul>
                        <li><span>{{ currentData.data.data[0].vItem[0].sTitle }}</span><span>{{ currentData.data.data[0].vItem[0].sDate }}</span></li>
                    </ul>
                </div>              
                <div v-else-if="isexist('fGoodwillValue') && currentData.data.data[0].fGoodwillValue!=0" class="goodwill_chart">
                    <div>商誉{{ currentData.data.data[0].fGoodwillValue }}亿</div>
                    <ul>  
                        <li>
                            <span>净资产{{ currentData.data.data[0].fNetAssetsValue.toFixed(2)}}亿,</span>
                            <span>占比{{ (currentData.data.data[0].fGoodwillValue/currentData.data.data[0].fNetAssetsValue).toFixed(2) }}%</span>
                        </li>
                        <li>
                            <span>总资产{{ currentData.data.data[0].fTotalAssetsValue.toFixed(2)}}亿,</span>
                            <span>占比{{ (currentData.data.data[0].fGoodwillValue/currentData.data.data[0].fTotalAssetsValue).toFixed(2) }}%</span>
                        </li>
                        <li>
                            <span>总市值{{ currentData.data.data[0].fTotalMarketValue.toFixed(2)}}亿,</span>
                            <span>占比{{ (currentData.data.data[0].fGoodwillValue/currentData.data.data[0].fTotalMarketValue).toFixed(2) }}%</span>
                        </li>
                    </ul> 
                    <div class="goodwill_desc">{{ currentData.data.data[0].sRiskDesc }}</div>              
                </div>
                <div v-else-if="isexist('sReduEndDate') && currentData.data.data[0].sReduEndDate!=''" class="redu_chart">
                    <div class="redu_plan">{{ currentData.data.data[0].sReduDate }}公布股份减持计划</div>                    
                    <div class="redu_desc">
                        <span>{{ currentData.data.data[0].sReduStartDate }}至{{ currentData.data.data[0].sReduEndDate }},</span>
                        <span>计划减持不超过{{ currentData.data.data[0].fReduMaxStockNum.toFixed(2) }}万股,</span>
                        <span>占总股本的{{ currentData.data.data[0].fReduMaxStockRatio }}%</span>
                    </div> 
                    <ul v-if="currentData.data.data[0].fHalfYearAccuReduStockNum!=0">
                        <li><span>过去半年累计减持：</span><span class="desc">{{ currentData.data.data[0].fHalfYearAccuReduStockNum.toFixed(2) }}万股</span></li>
                        <li><span>减持均价：</span><span class="desc color_desc">{{ currentData.data.data[0].fHalfYearAccuReduAvgPriv }}</span></li>
                        <li><span>占总股本：</span><span class="desc color_desc">{{ currentData.data.data[0].fHalfYearAccuReduRatio }}%</span></li>
                    </ul>           
                </div>
                <div v-else-if="isexist('sAuditResultSummary') && currentData.data.data[0].sAuditResultSummary!=''" class="audit_result_chart">
                    <div>{{ currentData.data.data[0].sAuditResultSummary }}</div>         
                </div>
                <div v-else-if="isexist('vNetAssetsItem') && currentData.data.data[0].vNetAssetsItem.length!=0" class="net_assets_chart">
                    <div class="net_assets_desc">{{ currentData.data.data[0].sRiskDesc }}</div> 
                    <bar-chart ref="refBarChart" id="refBarChart" customClass="na_bar_chart"></bar-chart>
                    <div class="chart_title">最近三年净利润(亿元)</div> 
                </div>
                <div v-else>
                  <div class="noData">{{ currentData.data.data[0].noData}}</div>                  
                </div>
            </div>        
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import donutChart from "@/components/donutChart/index_v1.0.vue";
import barChart from "@/components/barChart/index_v1.0.vue";


export default {
    data:function(){
        return{
            isStockMineSweepVisible:false,//相关历史数据+排名
            currentData:"",
            vItem:[],
            classData:"",
            seccode:"",

        }
    },
    components:{
        pageTitle,
        tableChart,
        donutChart,
        barChart
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        vRLength(options){
            if("vRiskItem" in options){
                if(options.vRiskItem.length!=0){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }

        },
        isexist(options){
            if(options in this.currentData.data.data[0]){
                return true
            }else{
                return false
            }

        }
        ,
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isStockMineSweepVisible = false;
                resolve();
            });
        },
        async show(commandData){
                this.isStockMineSweepVisible = false;

            $("#stockMineSweepPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["风险评估"].indexOf(commandData.type) > -1){
                    this.handleRiskLevel(commandData);
                }else if(["质押风险"].indexOf(commandData.type) > -1){
                    this.handlePledge(commandData)
                }else if(["退市风险"].indexOf(commandData.type) > -1){
                    this.handleNetAssets(commandData)
                }else if(["监管问询","诉讼仲裁"].indexOf(commandData.type) > -1){
                    this.handleClosure(commandData)
                }else{
                    this.handleCommon(commandData)
                }
            });
        },
/*==============================================*/
        handleRiskLevel(commandData){  
            this.classData="stock_msg"
            this.$nextTick(()=>{
                this.renderRiskLevel(commandData);
            });
        },
        renderRiskLevel(commandData) {
            this.currentData = commandData
            this.seccode = commandData.seccode.slice(4)
            this.isStockMineSweepVisible = true;
            this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1000,
                    delay: 500,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();   
            })
        },
/*==============================================*/
        handlePledge(commandData){  
            this.classData="stock_msg_else"
            this.$nextTick(()=>{
                this.renderPledge(commandData);
            });
        },
        renderPledge(commandData) {
            this.currentData = commandData
            this.seccode = commandData.seccode.slice(4)
            this.isStockMineSweepVisible = true; 
            console.log(commandData.data.data[0].item,'commandData.data.data[0].item,');
            this.$nextTick(()=>{          
                let width = this.$refs.refStockMineSweepHolder.clientWidth; 
                this.$refs.refDonutChart.DonutChart(commandData.data.data[0].item,{
                    name:(d)=>d.name,
                    value:(d)=>d.value,
                    width:width*0.3,
                    height:width*0.3,
                    donutRatioRange:[0.2,0.65,0.7],
                    colors:["#39B24E","#FF3B30","#FFB130"],
                    duration:1000,
                    delay:60,
                    valueType:"relative",
                    isRotateLabel:false,
                    labelOffset:42,
                    padAngle:0,
                })
            })
        },
/*==============================================*/
        handleNetAssets(commandData){  
            console.log(commandData,'commandData');
            this.classData="stock_msg_else"
            this.$nextTick(()=>{
                this.renderNetAssets(commandData);
            });
        },
        renderNetAssets(commandData) {
            this.currentData = commandData
            this.seccode = commandData.seccode.slice(4)
            this.isStockMineSweepVisible = true;             
            this.$nextTick(()=>{
                let width = this.$refs.refStockMineSweepHolder.clientWidth;
                this.$refs.refBarChart.BarChart(commandData.data.data[0].vNetAssetsItem,{
                    x: (d) => d.iYear,
                    y: (d) => 1*(d.fValue/100000000).toFixed(2),
                    width: document.body.clientWidth*0.5,
                    height: document.body.clientWidth * 0.2,
                    marginLeft: 50,
                    marginTop:60,
                    xPadding:0.6,
                    duration: 1000,
                    delay: 70,
                    ease: "easeCircle",
                    isShowNumber: true,
                    xTickNumber: 5,
                    yTickNumber: 5,
                })
            })
        },
/*==============================================*/
        handleClosure(commandData){  
            this.classData="stock_msg_else"
            this.$nextTick(()=>{
                this.renderClosure(commandData);
            });
        },
        renderClosure(commandData) {
            this.currentData = commandData
            this.seccode = commandData.seccode.slice(4)
            this.vItem = commandData.data.data[0].vItem.slice(0,2)
            this.isStockMineSweepVisible = true;            
        },
/*==============================================*/
        handleCommon(commandData){  
            console.log(commandData,'commandData');
            this.classData="stock_msg_else"
            this.$nextTick(()=>{
                this.renderCommon(commandData);
            });
        },
        renderCommon(commandData) {
            this.currentData = commandData
            this.seccode = commandData.seccode.slice(4)
            this.isStockMineSweepVisible = true;
        }

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>