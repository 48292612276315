<template>
  <div class="media_holder">
    <div :class="{ active_iframe: true, hidden: isIframeHidden }">
      <iframe :src="iframeSrc" id="iframeScroller"></iframe>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
export default {
  data: function () {
    return {
      iframeSrc: "", //当前活动图片
      isIframeHidden: true, //默认图片隐藏
      maxHeight: 4000, //iframe总高
      accumHeight: 0, //滚动累积高
    };
  },
  props: [],
  methods: {
    async show(src) {
      this.hide();
      await sleep(330);
      this.iframeSrc = src;
      await sleep(1 * 1000);
      this.isIframeHidden = false;
      await sleep(2.4 * 1000);
      this.accumHeight = 0;
      this.loopScrollTop();
    },
    async loopScrollTop() {
      if (this.isIframeHidden) {
        return;
      }
      this.accumHeight += 800;
      $("#iframeScroller").contents().find("html,body").animate({ scrollTop: this.accumHeight });
      await sleep(2.4 * 1000);
      if (this.accumHeight < this.maxHeight && !this.isIframeHidden) {
        this.loopScrollTop();
      }
    },
    async hide() {
      this.isIframeHidden = true;
      await sleep(100);
      this.iframeSrc = '';
    },
  },
  async mounted() {},
};
</script>
<style lang="less" scoped>
.media_holder {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  & > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.active_iframe {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
.hidden {
  opacity: 0 !important;
}
</style>
