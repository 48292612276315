<template>
  <div :id="id" :class="customClass" :style="`width:${width}px;height:${height}px`">
    <ul>
      <li
        v-for="(item, iIndex) in tableData"
        :key="iIndex"
        :class="{
          li_title: iIndex == 0,
          li_body: iIndex > 0,
        }"
      >
        <span
          v-for="(cell, cIndex) in item.slice(1)"
          :key="cIndex"
          :style="`background:${iIndex > 0 && iIndex < tableData.length - 1 ? summaryColors[cIndex] : 'transparent'}`"
        >
          <i>{{ item[0] }}</i
          ><i>{{ cell }}</i>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
import anime from "animejs/lib/anime.es.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      tableData: [],
      width: 300,
      height: 300,
      summaryColors: [],
    };
  },
  methods: {
    async playOut() {},
    //核心库
    SummaryTableChart(
      data,
      {
        width = 300,
        height = 300,
        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长
        ease = "linear",
        summaryColors = ["#000", "#555"],
      } = {}
    ) {
      this.width = width;
      this.height = height;
      this.tableData = data;
      this.summaryColors = summaryColors;

      this.$nextTick(() => {
        anime({
          targets: `#${this.id} li`,
          duration: duration,
          delay: anime.stagger(delay),
          easing: ease,
          opacity: [0, 1],
          translateY: [60, 0],
          translateX: [-60, 0],
          translateZ: [100, 0],
          scale: [0.8, 1],
        });
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
