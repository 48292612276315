<template>
  <div class="media_holder">
    <div :class="{ media_image: true, hidden: isImage1Hidden }" :style="`z-index:${activeImageIndex % 2 ? 1 : 10}`">
      <div class="background_image" :style="`background:url(${image1}) center center /cover;`"></div>
    </div>
    <div :class="{ media_image: true, hidden: isImage2Hidden }" :style="`z-index:${activeImageIndex % 2 ? 10 : 1}`">
      <div class="background_image" :style="`background:url(${image2}) center center /cover;`"></div>
    </div>

    <div :class="{ media_video: true, hidden: isVideo1Hidden }">
      <video
        :src="video1"
        autoplay
        muted
        :loop="isLoop"
        style="object-fit: cover; width: 100%; height: 100%; display: block"
      ></video>
    </div>
    <div :class="{ media_video: true, hidden: isVideo2Hidden }">
      <video
        :src="video2"
        autoplay
        muted
        :loop="isLoop"
        style="object-fit: cover; width: 100%; height: 100%; display: block"
      ></video>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
export default {
  data: function () {
    return {
      image1: "", //图片1
      image2: "", //图片2
      activeImageIndex: 0,
      isImage1Hidden: true, //默认图片隐藏
      isImage2Hidden: true, //默认图片隐藏

      video1: "", //视频1
      video2: "", //视频2
      activeVideoIndex: 0,
      isVideo1Hidden: true, //默认视频隐藏
      isVideo2Hidden: true, //默认视频隐藏
    };
  },
  props: ["isLoop"],
  methods: {
    async show(src) {
      if (src.substr(-3) == "mp4") {
        this.activeVideoIndex += 1;
        this.videoIn(src);
      } else {
        this.activeImageIndex += 1;
        this.imageIn(src);
      }
    },
    hide() {
      this.imageOut();
      this.videoOut();
    },
    //只考虑视频上cover视频，和图片上cover图片，不混合cover
    async imageIn(src) {
      const imageIndex = (this.activeImageIndex % 2) + 1;
      const otherImageIndex=imageIndex==1?2:1
      this[`image${imageIndex}`] = "";
      this[`isImage${imageIndex}Hidden`] = true;
      await sleep(150);
      this[`image${imageIndex}`] = src;
      this[`isImage${imageIndex}Hidden`] = false;
      await sleep(150);
      this[`isImage${otherImageIndex}Hidden`] = true;
    },
    async imageOut() {
      this.isImage1Hidden = true;
      this.isImage2Hidden = true;
      await sleep(300);
      this.image1 = "";
      this.image2 = "";
    },
    async videoIn(src) {
      const videoIndex = (this.activeVideoIndex % 2) + 1;
      this[`video${videoIndex}`] = "";
      this[`isVideo${videoIndex}Hidden`] = true;
      await sleep(300);
      this[`video${videoIndex}`] = src;
      this[`isVideo${videoIndex}Hidden`] = false;
    },
    async videoOut() {
      this.isVideo1Hidden = true;
      this.isVideo2Hidden = true;
      await sleep(300);
      this.video1 = "";
      this.video2 = "";
    },
  },
  async mounted() {},
};
</script>
<style lang="less" scoped>
.media_holder {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  & > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.media_image,
.media_video {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  .background_image {
    width: 100%;
    height: 100%;
  }
}
.hidden {
  opacity: 0 !important;
}
</style>
