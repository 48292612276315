<template>
    <div id="duPonChart">
        <div class="pages hidden" id="duPonPages">
            <page-title id="duPonChartText" ref="refPageTitle"></page-title>
            <div ref="refIndustryScatterHolder" v-if="isIndustryScatterVisible">
                <table-chart ref="refIndustryScatterTable" id="industryScatterTable" customClass="industry_scatter_table_chart"/>
            </div>
            <div ref="refStockYearChangeHolder" v-if="isStockYearChangeVisible">                
                <chart-legend ref="refStockYearChangeLegend" id="stockYearChangeLegend" class="work_legend" />
                <line-chart ref="refStockYearChangeLine" id="stockYearChangeLine" customClass="stock_year_change_line_chart"/>
                <table-chart ref="refStockYearChangeTable" id="stockYearChangeTable" customClass="stock_year_change_table_chart"/>
            </div> 
            <div ref="refAnalysisPicHolder" v-if="isAnalysisPicVisible">
                <custom-tree ref="refAnalysisPicTable" id="analysisPicTable" customClass="analysis_pic_tree_chart"/>
            </div>           
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import customTree from "@/components/customTree/customTree_dupon.vue";
import chartLegend from "@/components/chartLegend/index.vue";


export default {
    data:function(){
        return{
            isIndustryScatterVisible:false,//相关历史数据+排名
            isStockYearChangeVisible:false,//走势
            isAnalysisPicVisible:false,//详情

        }
    },
    components:{
        pageTitle,
        tableChart,
        lineChart,
        customTree,
        chartLegend,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isIndustryScatterVisible = false;
                this.isStockYearChangeVisible = false;
                this.isAnalysisPicVisible = false;
                this.updateDate ="";
                resolve();
            });
        },
        async show(commandData){
                this.isIndustryScatterVisible = false;
                this.isStockYearChangeVisible = false;
                this.isAnalysisPicVisible = false;

            $("#duPonPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["ROE相关历史数据","排名"].indexOf(commandData.type) > -1){
                    this.handleIndustryScatter(commandData);
                }else if (["走势详情"].indexOf(commandData.type) > -1){
                    this.handleStockYearChange(commandData);
                }else if (commandData.type == "ROE详情"){
                    this.handleAnalysisPic(commandData);
                }
            });
        },
/*=====================个股百科-历史相关数据+排名=========================*/
        handleIndustryScatter(commandData){
            this.isIndustryScatterVisible = true;
            this.$nextTick(()=>{
                this.renderIndustryScatter(commandData);
            });
        },
        renderIndustryScatter(commandData) {
            let width = this.$refs.refIndustryScatterHolder.clientWidth;
            this.$refs.refIndustryScatterTable.TableChart(commandData.data.data,{
                width: width,
                height: width * 0.77,
                marginBottom:100,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
        },
        
/*=====================个股百科-走势详情=========================*/ 
        handleStockYearChange(commandData){            
            this.isStockYearChangeVisible = true;  
            this.$nextTick(()=>{
                this.renderStockYearChange(commandData);
            });
        },   
        renderStockYearChange(commandData){            
            //展示legend
            this.$refs.refStockYearChangeLegend.createLegend({
                legend: commandData.data.data.legend,
                legendType: ["dot","dot"],
                colors: ["#65BBFF", "#FF7700"],
            });
            let width = this.$refs.refStockYearChangeHolder.clientWidth; 
            if(["流动比","权益乘数","速动比","利息保障倍数"].indexOf(commandData.data.data.type) > -1){
              this.$refs.refStockYearChangeLine.LineChart(commandData.data.data.chartData,{
                label: (d) => d.label,
                width: width,
                height: width * 0.5,
                marginLeft: 100,
                marginRight:120,
                marginTop: 50,
                marginBottom: 50,
                duration: 900,
                delay: 50,
                ease: "easeCubic",
                dotCircleRadius: 0,
                isShowTxtTips: false,
                isFromZero: false,
                xTickNumber:3,
                yTickNumber: 7,
                unit:"",
                });              
            }else if (["存货周转率"].indexOf(commandData.data.data.type) > -1){
                this.$refs.refStockYearChangeLine.LineChart(commandData.data.data.chartData,{
                label: (d) => d.label,
                width: width,
                height: width * 0.5,
                marginLeft: 200,
                marginRight:120,
                marginTop: 50,
                marginBottom: 50,
                duration: 900,
                delay: 50,
                ease: "easeCubic",
                dotCircleRadius: 0,
                isShowTxtTips: false,
                isFromZero: false,
                xTickNumber:3,
                yTickNumber: 7,
                unit:"%",
                });
            }else {
                this.$refs.refStockYearChangeLine.LineChart(commandData.data.data.chartData,{
                label: (d) => d.label,
                width: width,
                height: width * 0.5,
                marginLeft: 100,
                marginRight:120,
                marginTop: 50,
                marginBottom: 50,
                duration: 900,
                delay: 50,
                ease: "easeCubic",
                dotCircleRadius: 0,
                isShowTxtTips: false,
                isFromZero: false,
                xTickNumber:3,
                yTickNumber: 7,
                unit:"%",
                });              
            }
            this.$refs.refStockYearChangeTable.TableChart(commandData.data.data.tableData,{
                width: width,
                height: width * 0.2,
                marginBottom:50,
                duration: 500,
                delay: 100,
                ease: "easeOutCubic",
            });
        },
/*=====================个股百科-ROE详情=========================*/
        handleAnalysisPic(commandData){
            this.isAnalysisPicVisible = true;
            this.$nextTick(()=>{
                this.renderAnalysisPic(commandData);
            });
        },
        renderAnalysisPic(commandData) {
            let fontSize = document.documentElement.style.fontSize
            let number = window.parseFloat(fontSize)
            let width = this.$refs.refAnalysisPicHolder.clientWidth;
            this.$refs.refAnalysisPicTable.CustomTreeChart(commandData.data.data,{
                width: width,
                height: width*0.92,
                lineWidth:14*number,
                nodeLength:150,
                branchNodeWidth:11.5*number,
                branchNodeHeight:4.5*number

            });
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>