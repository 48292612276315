<template>
    <div class="addition_lifted_detail">
        <ul>
            <li>
                <span>{{ capitalDeal(data.fFund) }}</span>
                <span>解禁金额</span>
            </li>
            <li>
                <span>{{ data.fFundRate.toFixed(2) }}%</span>
                <span>解禁资金占比</span>
            </li>
            <li>
                <span>{{ data.fPriceRate.toFixed(2) }}%</span>
                <span>破发比率</span>
            </li>
        </ul>
        <div>
            <div class="left">
                <h3>解禁详情</h3>
                <ul>
                    <li><span>解禁日期</span><span>{{ data.sDate }}</span></li>
                    <li><span>收盘价</span><span>{{  data.fClosePrice}}元</span></li>
                    <li><span>增发价</span><span>{{  data.fAdditionPrice}}元</span></li>
                    <li><span>解禁股数</span><span>{{  data.fLiftedCount}}万股</span></li>
                    <li><span>锁定日期</span><span>{{ data.iLockexMonth }}个月</span></li>
                    <li><span>所属行业</span><span>{{ data.sIndustry }}</span></li>
                </ul>
            </div>
            <div class="right">
                <h3>解禁股东</h3>
                <ul>
                    <li v-for="(item, index) in data.vLiftedDetail" :key="index">
                        <span>{{ index + 1}}</span>
                        <div>
                            <p>{{ item.sName }}</p>
                            <div>{{ item.fLiftedCount }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data'],
    methods: {
        capitalDeal(fZljlr) {
            var capitalNumb;
            if(fZljlr===""){
                capitalNumb= "--万元";
            }
            fZljlr = Math.abs(fZljlr);
            if(fZljlr>0&&fZljlr<10000){
                capitalNumb= `${fZljlr.toFixed(2)}万元`;
            }else if(fZljlr>=10000){
                capitalNumb= `${(fZljlr/10000).toFixed(2)}亿元`;
            }
            return capitalNumb;
        },
    }
}
</script>
<style lang="less" scoped>
.addition_lifted_detail {
    margin-top: 70px;
    > ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 40px;
        > li {
            width: 166px;
            height: 166px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            > span:first-child {
                font-size: 26px;
            }
            &:nth-child(1) {
                background: #FF3B30;
            }
            &:nth-child(2) {
                background: #FF7700;
            }
            &:nth-child(3) {
                background: #3C88D4;
            }
        }
    }
    > div {
        display: flex;
        justify-content: space-between;
        h3 {
            font-size: 28px;
            margin-bottom: 10px;
        }
        > div {
            flex: 1;
        }
        .left {
            flex: 1;
            padding-right: 130px;
            box-sizing: border-box;
            > ul {
                font-size: 24px;
                >li {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;
                }
            }
        }
        .right {
            flex: 1;
            > ul {
                font-size: 24px;
                > li {
                    display: flex;
                    margin-bottom: 10px;
                    > span {
                        width: 28px;
                        height: 28px;
                        font-size: 22px;
                        text-align: center;
                        line-height: 28px;
                        border-radius: 50%;
                        background: #FCA30A;
                        margin-right: 12px;
                        color: #fff;
                        margin-top: 4px;
                    }
                    > div {
                        flex: 1;
                        > p {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}
</style>