<template>
  <div id="resultsContrast">
    <div class="pages hidden" id="resultsContrastPages">
      <div class="page_title"><span class="text hidden" id="resultsContrastText"></span></div>
      <div ref="refResultsContrastHolder" v-if="isResultsContrastVisible" class="resultsContrastHolder">
        <ul class="stock_msg">
          <li v-for="item in commandData.compareStocks" :key="item.seccode">
            <span>{{ item.secname }}</span>
            <span>{{ item.seccode }}</span>
          </li>
        </ul>
        <ul class="contrast_msg">
          <li class="plate_item">
            <span>盈利得分能力：</span>
            <span v-for="(item, index) in commandData.data.data" :key="index">{{ item['盈利能力'] }}</span>
          </li>
          <li class="plate_item">
            <span>偿债分能力：</span>
            <span v-for="(item, index) in commandData.data.data" :key="index">{{ item['偿债能力'] }}</span>
          </li>
          <li class="plate_item">
            <span>成长得分能力：</span>
            <span v-for="(item, index) in commandData.data.data" :key="index">{{ item['成长能力'] }}</span>
          </li>
          <li class="plate_item">
            <span>运营得分能力：</span>
            <span v-for="(item, index) in commandData.data.data" :key="index">{{ item['运营能力'] }}</span>
          </li>
        </ul>
      </div>
      <div ref="refResultsContrastBarHolder" v-if="isResultsContrastBarVisible">
        <bar-chart ref="refResultsContrastBar" id="resultsContrastBar" customClass="bar_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import barChart from "@/components/barChart/index_v1.0.vue";

export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      isResultsContrastVisible: false,
      isResultsContrastBarVisible: false,
      commandData: {},
    };
  },
  components: {
    barChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#resultsContrastText").addClass("hidden");
        this.isResultsContrastVisible = false;
        this.isResultsContrastBarVisible = false;
        this.commandData = {}
        resolve();
      });
    },
    async show(commandData) {
      this.isResultsContrastVisible = false;
      this.isResultsContrastBarVisible = false;
      this.commandData = {}
      //设置标题
      const title = commandData.data.pageTitle;
      $("#resultsContrastPages").removeClass("hidden");
      $("#resultsContrastText").html(title);

      this.$nextTick(async () => {
        $("#resultsContrastText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#resultsContrastText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (['综合对比'].indexOf(commandData.type) !== -1) {
          this.handleResultsContrast(commandData)
        } else {
          this.handleResultsContrastBar(commandData)
        }
      });
    },

    handleResultsContrast(commandData) {
      this.isResultsContrastVisible = true;
      this.$nextTick(() => {
        this.renderResultsContrast(commandData)
      });
    },
    renderResultsContrast(commandData) {
      this.commandData = commandData
      this.$nextTick(() => {
            this.ffRow = anyElement({
                targets: document.querySelectorAll(".plate_item"),
                isSplitTxt: false,
                duration: 1000,
                delay: 500,
                easing: "easeOutExpo",
                inEffect: "topIn",
                outEffect: "leftOutBig",
            });
            this.ffRow.runIn();
        })
    },

    handleResultsContrastBar(commandData) {
      this.isResultsContrastBarVisible = true;
      this.$nextTick(() => {
        this.renderResultsContrastBar(commandData)
      });
    },
    renderResultsContrastBar(commandData) {
      let suffix = ""
      let data = commandData.data.data.map((item) => {
        if (!suffix) {
          suffix = item.value.indexOf('%') !== -1 ? '%' : item.value.indexOf('天') !== -1 ? '天' : ''
        }
        return {
          label: item.label,
          value: Number(item.value.replace(/\%|天/, ''))
        }
      })
      let width = this.$refs.refResultsContrastBarHolder.clientWidth;
      this.$refs.refResultsContrastBar.BarChart(data, {
        x: (d) => d.label,
        y: (d) => d.value,
        width: width,
        height: width * 0.76,
        marginLeft: 100,
        marginBottom: 40,
        marginTop: 60,
        duration: 1000,
        delay: 70,
        ease: "easeCircle",
        isShowNumber: true,
        xPadding: 0.7,
        numberSuffix: suffix,
        ySuffix: suffix,
        yTickNumber: 4,
      });
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
