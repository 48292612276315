<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async doubleYLinesChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain1, // [ymin, ymax]
        yDomain2,
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xPadding = 0.3,
        isShowTxtTips = true, //是否显示文字提示 默认开启
        dotCircleRadius = 0, //默认dot半径为0        
        xTickNumber = 5,
        yTickNumber = 7,
        numberSuffix = ["","%"], //每根线提示数据的单位
        lineColors=[],
        lineWidth = [],
        unit ="",
      } = {}
    ) {
      this.duration = duration;

      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const line1Max = d3.max(data, (d) => d.line1);
      const line1Min = d3.min(data, (d) => d.line1);
      const line2Max = d3.max(data, (d) => d.line2);
      const line2Min = d3.min(data, (d) => d.line2);
      const line3Max = d3.max(data, (d) => d.line3);
      const line3Min = d3.min(data, (d) => d.line3);
      let lineMax =[line2Max,line3Max].max();

      console.log(data,"data");
     //提前知道线指有几个小数点
      const demicalDigit1 = demicalLength(data[0].line1);
      const demicalDigit2 = demicalLength(data[2].line2);
            //x的数值范围
      if (xDomain === undefined) xDomain = X;
      //数值范围
      if (yDomain1 === undefined) yDomain1 = [0, line1Max]
      //line的数值范围
      // if (yDomain2 === undefined) yDomain2 = [0, line2Max];
      if (yDomain2 === undefined) yDomain2 = [0, lineMax];

      //line的数值范围
      // if (yDomain3 === undefined) yDomain3 = [line3Min, line3Max];

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale1 = d3.scaleLinear(yDomain1, yRange);
      const yScale2 = d3.scaleLinear(yDomain2, yRange);
      // const yScale3 = d3.scaleLinear(yDomain3, yRange);
      const yMagnify1 = d3.scaleLinear().domain(yRange).range(yDomain1); //计算y轴坐标和value对应关系 yMagnify(y)=>value
      const yMagnify2 = d3.scaleLinear().domain(yRange).range(yDomain2);
      // const yMagnify3 = d3.scaleLinear().domain(yRange).range(yDomain3);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画网格
      const drawGrid = () => {
        const xArr = [];
        if (xTickNumber == 1) {
          let index = parseInt(data.length / 2);
          xArr.push(xScale.domain()[index]);
        } else if (xTickNumber == 2) {
          xArr.push(xScale.domain()[0]);
          xArr.push(xScale.domain()[data.length - 1]);
        } else if (xTickNumber > 2) {
          for (let i = 0; i < xTickNumber; i++) {
            let index = Math.ceil(i * ((data.length - 1) / (xTickNumber - 1)));
            index = index > data.length - 1 ? data.length - 1 : index;
            xArr.push(xScale.domain()[index]);
          }
        }
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).tickValues(xArr);
        const axisX = svg
          .append("g")
          .attr("class", "axis_x")
          .attr("transform", `translate(0,${height - marginBottom})`)

          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("text-anchor", (d, i) => {
                return "middle";
            })
          });
        axisX.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisX.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisX
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);


        const yAxis1 = d3.axisLeft(yScale1).ticks(yTickNumber).tickSizeOuter(0);
        const axisY1 = svg
          .append("g")
          .attr("class", "axis_y axis_y1")
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis1)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          });
        axisY1.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisY1.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisY1
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);

        const yAxis2 = d3.axisRight(yScale2).ticks(yTickNumber).tickSizeOuter(0);
        const axisY2 = svg
          .append("g")
          .attr("class", "axis_y axis_y2")
          .attr("transform", `translate(${width - marginRight},0)`)
          .call(yAxis2)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").text((d) => {
            return `${d.toFixed(1)}${unit}`
          }).attr("opacity", 0);
          });
        axisY2.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisY2.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisY2
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);
      };
      drawGrid();

     

      //画面积
      const drawArea = () => {
        var area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.label);
          }) //对x轴进行缩放
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale2(d.line2);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg.append("g").attr("class", "area_group");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient
          .append("stop")
          .attr("class", "area_top")
        //   .attr("stop-color", "#178CEA")
        //   .attr("stop-opacity", "0.42")
          .attr("offset", "0%");

        linearGradient
          .append("stop")
          .attr("class", "area_bottom")
        //   .attr("stop-color", "#178CEA")
        //   .attr("stop-opacity", "0.16")
          .attr("offset", "100%");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([data[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attrTween("d", () => {
            let index = d3.interpolate(0, data.length - 1);
            return (t) => {
              let deg = Math.round(index(t));
              // console.log(deg);
              return area(data.slice(0, deg + 1));
            };
          });
      };
      drawArea();


       //画线
      const drawLine = () => {
        const lineData = [data.map((d) => d.line1), data.map((d) => d.line2), data.map((d) => d.line3)];
        const lineGroup = svg.append("g").attr("class", "line_group");
        for (let j = 0; j < lineData.length; j++) {          
        const color = !lineColors[j] ? "#000" : lineColors[j];
        const width = !lineWidth[j] ? 1 : lineWidth[j];
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => {return(xScale.bandwidth() / 2 + xScale(X[i]))})
            .y((i) => (j == 0 ? yScale1(Y[i]) : yScale2(Y[i])));

          const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", `line line${j + 1}`)
            .attr("stroke", "black")
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = svgLine.node().getTotalLength();
          this.svgLineTotalArr[j] = svgLineTotalLength;
          svgLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
            
            //画线上的dot
            const drawLineDot = async () => {
                const dotCircle = svg
                .append("g")
                .attr("class", `dot_circle_group${j + 1}`)
                .selectAll("circle")
                .data(lineData[j])
                .join("circle")
                .attr("cx", (d, i) => xScale.bandwidth() / 2 + xScale(X[i]))
                .attr("cy", (d) => (j == 0 ? yScale1(d) : yScale2(d)))
                .attr("r", dotCircleRadius * 0.1)
                .attr("class", "dot")
                .attr("fill", "#f00")
                .attr("opacity", "0");
                dotCircle
                .transition()
                .duration(duration)
                .ease(d3[ease])
                .delay((d, i) => i * 30)
                .attr("r", dotCircleRadius)
                .attr("opacity", "1");
            };
            drawLineDot();
            if (isShowTxtTips == true) {
              //圆点
              let circle = svg
                .append("circle")
                .attr("cx", xScale(X[0]))
                .attr("cy", (j == 0 ? yScale1(Y[0]) : yScale2(Y[0])))
                .attr("r", width)
                .attr("class", `circle circle${j + 1}`)
                .attr("stroke-width", width / 2)
                .attr("stroke", color)
                .attr("fill", "#fff");
              let circleTran = circle
                .transition()
                .duration(duration)
                .ease(d3[ease])
                .tween("attr.circle", function (d, i, a) {
                  return function (t) {
                    let x = svgLine.node().getPointAtLength(svgLineTotalLength * t).x;
                    let y = svgLine.node().getPointAtLength(svgLineTotalLength * t).y;
                    d3.select(this).attr("cx", x).attr("cy", y);
                  };
                });
              //文字
              let text = svg
                .append("text")
                .attr("fill", color)
                .attr("x", xScale(X[0]))
                .attr("y", (j == 0 ? yScale1(Y[0]) : yScale2(Y[0])))
                .attr("class", `valueTip valueTip${j + 1}`)
                .attr("text-anchor", "end")
                .text(0);

              if (j==0){
                let textTran = text
                    .transition()
                    .duration(duration)
                    .ease(d3[ease])
                    .tween("attr.text", function (d, b, a) {
                      return function (t) {
                        let x = svgLine.node().getPointAtLength(svgLineTotalLength * t).x;
                        let y = svgLine.node().getPointAtLength(svgLineTotalLength * t).y;
                        d3.select(this)
                          .attr("x", x + 5)
                          .attr("y", () => {
                            return y;
                          })
                          .text(() => {
                              return yMagnify1(y).toFixed(demicalDigit1) + numberSuffix[0];                            
                          });
                      };
                    });
              }else{
                let textTran = text
                  .transition()
                  .duration(duration)
                  .ease(d3[ease])
                  .tween("attr.text", function (d, b, a) {
                    return function (t) {
                      let x = svgLine.node().getPointAtLength(svgLineTotalLength * t).x;
                      let y = svgLine.node().getPointAtLength(svgLineTotalLength * t).y;
                      d3.select(this)
                        .attr("x", x + 5)
                        .attr("y", () => {
                          return y;
                        })
                        .text(() => {
                            return yMagnify2(y).toFixed(demicalDigit2) + numberSuffix[1];
                        });
                    };
                  });               
              }
              

            }
    
        }
      };
      drawLine();
      

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
