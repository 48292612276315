<template>
  <div :class="{ marquee_holder: true, hidden: !isShow }">
    <div class="title">{{ title }}</div>
    <div class="marquee text_marquee" id="textMarquee">
      <div class="marquee-inner">
        <div class="marquee-content">
          <div>
            {{ marqueeTxt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import InfiniteMarquee from "infinite-marquee";
export default {
  props: [],
  data() {
    return {
      title: "直播提示：本内容仅供参考，不构成投资建议，股市有风险，投资需谨慎。", //主标题
      subTitle: "", //附标题
      marqueeTxt: "", //滚动文字
      isShow: false,
    };
  },
  methods: {
    show(options) {
      this.isShow = true;
      this.marqueeTxt = options.marqueeTxt;
      this.title = options.title;
      this.subTitle = options.subTitle;
      this.$nextTick(() => {
        this.initTextMarquee();
      });
    },
    hide() {
      this.isShow = false;
    },
    initTextMarquee() {
      new InfiniteMarquee({
        el: document.querySelector("#textMarquee"),
        direction: "left",
        duration: 40,
        css: true,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
@import "infinite-marquee/assets/css/infinite-marquee";
</style>
