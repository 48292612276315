<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {};
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.bar`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .attr("width", "0")
        .attr("x", "0")
        .ease(d3.easeCubicIn);
      this.svg
        .selectAll(`.tick`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .style("opacity", "0")
        .ease(d3.easeCubicIn);
      this.svg
        .selectAll(`.numbers`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .style("opacity", "0")
        .ease(d3.easeCubicIn);
    },
    sum(obj, keys = []) {
      let num = 0;
      keys.forEach((i) => {
        num += obj[i];
      });
      return num;
    },
    xSum(fn, obj, keys) {
      let num = 0;
      keys.forEach((i) => {
        num += fn(obj[i]);
      });
      return num;
    },
    //核心库
    StackedBarChart(
      data,
      {
        x = (d) => d, //横向的是数值
        marginTop = 80, // the top margin, in pixels
        marginRight = 60, // the right margin, in pixels
        marginBottom = 80, // the bottom margin, in pixels
        marginLeft = 60, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xPadding = 0.6, // 柱子的比例
        duration = 1000, //动画持续时长
        delay = 400, //元素之间间隔时长
        ease = "easeCubicOut", //元素之间间隔时长
        colors = ["#ff3600", "#f76f00", "#e19a00", "#c0c000", "#8ee100", "#01ff37"],
        keys = [],           
        yTickNumber = 5, 
        yFormat = ",f",   
      } = {}
    ) {
      
      const X = d3.map(data, x);



      // 创建svg
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //数出有几组value
      for (const key in data[0]) {
        if (key.indexOf("value") == 0) {
          keys.push(key);
        }
      }

      // 绘制区域
      const innerWidth = width - marginRight - marginLeft;
      const innerHeight = height - marginTop - marginBottom;
      // 定义比例尺
      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data.map((d) => this.sum(d, keys)))])
        .range([innerHeight ,0]);
      const xScale = d3.scaleBand().domain(X).range([0, innerWidth]).padding(xPadding);
      // 定义堆叠器
      const stack = d3.stack().keys(keys).offset(d3.stackOffsetNone);
      // const stack = d3.stack().keys(keys).order(d3.stackOrderAscending).offset(d3.stackOffsetNone);   //order可以让数据排序
      // y轴
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber, yFormat).tickSizeOuter(0);
      const xAxis = d3.axisTop(xScale).tickSizeOuter(0);
      $("#" + this.id).html(svg.node());
      // 绘制y轴
      svg.append("g")
        .attr("class", "axis_y")
        .call(yAxis)
        .attr("transform", `translate(${marginLeft},${marginTop})`)
        .call((g) => {
          g.select(".domain").attr("class", "domain").attr("opacity", 1);
          g.selectAll(".tick line")
            .attr("class", "tick_line")
            .attr("opacity", 1);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke", "rgba(0,0,0,.1)")
            .attr("class", "tick_long_line");
          g.selectAll(".tick text").attr("class", "text");
        })

       // 绘制x轴
      svg.append("g")
        .attr("class", "axis_x")
        .call(xAxis)
        .attr("transform", `translate(${marginLeft},${height - marginBottom})`)
        .call((g) => {
           g.select(".domain").attr("class", "domain").attr("opacity", 1);;
          g.selectAll(".tick line")
            .attr("class", "tick_line")
            .attr("opacity", 0);
          g.selectAll(".tick text")
            .attr("class", 'text');
        })
      // 绘制堆叠柱图
      let barGroup = svg.selectAll(".barGroup").data(stack(data));
      let bars = barGroup
        .enter()
        .append("g")
        .attr("transform", `translate(${marginLeft},${marginTop})`)
        .merge(barGroup)
        .attr("class", "barGroup")
        .attr("fill", (d, i) => colors[i])
        .selectAll(".bar")
        .data((d) => {
          return d.map((item) => {
            item.index = d.index;
            item.name = d.key;
            return item;
          });
        });
      bars
        .enter()
        .append("rect")
        .attr("class", "bar")
        .merge(bars)
        .attr("x", (d, i) => xScale(X[i]))
        .attr("width", xScale.bandwidth())
        .attr("height", 0)
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
        .attr("y", (d) => yScale(d[1]));

    },
  },
};
</script>
