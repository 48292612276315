<template>
  <div id="auction">
    <div class="pages hidden" id="auctionPages">
      <page-title id="auctionText" ref="refPageTitle"></page-title>

      <div class="page_date">{{ updateDate }}</div>

      <div ref="refBiddingChartHolder" v-if="isAuctionSummaryVisible">
        <bidding-Chart ref="refBiddingChart" id="biddingChart" customClass="bidding_chart" />
      </div>

      <div ref="refAuctionTableHolder" v-if="isAuctionTableVisible">
        <table-chart ref="refAuctionTable" id="auctionTable" customClass="auction_table_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import biddingChart from "@/components/marketChart/biddingChart.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      updateDate: "", //数据更新日期
      isAuctionSummaryVisible: false, //集合竞价summary
      isAuctionTableVisible: false, //集合竞价各种详情
    };
  },
  components: {
    pageTitle,
    tableChart,
    biddingChart,
  },
  methods: {
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        this.isAuctionSummaryVisible = false;
        this.isAuctionTableVisible = false;
        this.updateDate = "";
        resolve();
      });
    },
    async show(commandData) {
      this.isAuctionSummaryVisible = false;
      this.isAuctionTableVisible = false;
      $("#auctionPages").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);
      this.$nextTick(async () => {
        await sleep(300);
        if (commandData.type == "auctionSummary") {
          this.handleAuctionSummary(commandData);
        } else {
          this.handleAuction(commandData);
        }
      });
    },
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    handleAuctionSummary(commandData) {
      this.isAuctionSummaryVisible = true;
      this.$nextTick(() => {
        this.renderBiddingChart(commandData);
      });
    },
    renderBiddingChart(commandData) {
      let width = this.$refs.refBiddingChartHolder.clientWidth;
      this.$refs.refBiddingChart.biddingChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 50,
        marginBottom: 80,
        duration: 500,
        delay: 100,
        ease: "easeBack",
        xPadding: 0.5,
        valueSuffix: "只",
      });
      this.updateDate = commandData.data.date;
    },
    handleAuction(commandData) {
      this.isAuctionTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      let width = this.$refs.refAuctionTableHolder.clientWidth;
      this.$refs.refAuctionTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col2Suffix: "%",
        col3Suffix: ["auctionUpTrend", "auctionDownTrend"].indexOf(commandData.type) > -1 ? "%" : "",
      });
      this.updateDate = commandData.data.date;
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
